const MediaQueries = {
  t: {
    query: "only screen and (min-width:420px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  ts: {
    query: "only screen and (min-width: 320px) and (max-width: 576px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  s: {
    query: "only screen and (min-width:576px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },

  m: {
    query: "only screen and (min-width:768px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  mtl: {
    query: "only screen and (min-width:768px) and (max-width:992px) ",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  l: {
    query: "only screen and (min-width:992px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  xl: {
    query: "only screen and (min-width:1200px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
  xxl: {
    query: "only screen and (min-width:1400px)",
    sizes: {
      NavbarLogo: "22.4px",
      TitleText: "20.8px",
      subTitleText: "16px",
    },
  },
};

const Fonts = {
  Quicksand: `'Quicksand', sans-serif`,
  Poppins: `'Poppins', sans-serif;`,
  Inter: `'Inter', sans-serif;`,
};

export const LightTheme = {
  Colors: {
    AcademicCalendarPrevDates: "#BDE7FF",
    AcademicCalendarDates: "#191D29", 
    AcademicCalendarBg: "#f2f9ff",
    AcademicCalendarBorder: "#BDE7FF",
    SearchBar: "#d5dbed4f",
    FooterBg: "#043D5D",
    FooterTextPrimary: "#BDE7FF",
    FooterTextSecondary: "#317AA3",
    PageBg: "#FFFFFF",
    NavbarSideNavBg: "#FFFFFF",
    HeaderText: "#1D516F",
    BodyText: "#959AAC",
    YellowBtn: "#FFC267",
    YellowBtnHover: "#FFB13D",
    LightBlueBtn: "#00C2FF",
    LightBlueBtnHover: "#00C2FF",
    BlueBtn: "linear-gradient(5.55deg, #00C2FF 4.96%, #005382 96.5%)",
    LabelText: "#B3B8CC",
    PlaceholderBg: "#F7FAFF",
    PlaceholderText: "#626B70",
    PlaceholderBorder: "#D5DBED",
    BellIcon: "#C4C4C4",
    CardBorder: "#C8CBD7",
    LightGrey: "#BEC5D2",
    Black: "#000000",
    PaginationDisabled: "#d5dbed7d",
    White: "#ffffff",
    GithubBg: "#000000",
    Load: "#4198FF",
    LoadBg: "#F0F3FB",
    Like: "#fe5d5d",
    UnLike: "#E1E1E1",
    Blackwhite: "#000000",
    PageNum: "#959AAC",
    ActivePage: "#83CCFF",
    TabText: "#C8CBD7",
    Title: "#1D516F",
    SubTitle: " #8995B5",
    ImageText: "#DCF2FF",
    BlogAuthor: "#4D565B",
    BlogDate: "#C8CBD7",
    FilterHeading: "#527289",
    Profileicon: "#161614",
    Dashboard: "#ffffff",
    PastRole: "#B1D4FF",
    PopUpText: "#000000",
    Shadow:
      "0px 0px 100px rgba(0, 0, 0, 0.06),0px 17.1113px 34.9987px rgba(0, 0, 0, 0.0279534),0px 9.4682px 3.4133px rgba(0, 0, 0, 0.00917414);",
    ScrollBg: "rgba(213, 219, 237, 0.31)",
    ScrollThumbBg: "#ccd7f7",
    PseudoProfile: "#418df9",
    EmptyImg: "#DEE9FF",
    RegButtonBg: "#FFC2673D",
    RegButton: "#FFB13D",
    Card: "#4198FF",
    TagButton: "#e1ecf4",
    TextLink: "#0074cc",
    Dot: "#7395A8",
    Closed: "transparent",
    unseenNotification: "#C8CBD7",
  },
  MediaQueries: MediaQueries,
  Fonts: Fonts,
};

export const DarkTheme = {
  Colors: {
    AcademicCalendarPrevDates: "#385E73",
    AcademicCalendarDates: "#BDE7FF",
    AcademicCalendarBg: "#082E40",
    AcademicCalendarBorder: "#082E40",
    SearchBar: "#d5dbed4f",
    FooterBg: "#06141C",
    FooterTextPrimary: "#BDE7FF",
    FooterTextSecondary: "#7395A8",
    PageBg: "#001825",
    NavbarSideNavBg: "#022A40",
    HeaderText: "#BDE7FF",
    BodyText: "#7395A8",
    YellowBtn: "#FF9F0F",
    LightBlueBtn: "#4198FF",
    YellowBtnHover: "#FF9900",
    LightBlueBtn: "#00C2FF",
    LightBlueBtnHover: "#00C2FF",
    BlueBtn: "linear-gradient(5.55deg, #00C2FF 4.96%, #005382 96.5%)",
    LabelText: "#317AA3",
    PlaceholderBg: "#001C2A",
    PlaceholderText: "#96C3DC",
    PlaceholderBorder: "#083F5F",
    BellIcon: "#125478",
    CardBorder: "#C8CBD7",
    LightGrey: "#BEC5D2",
    Black: "#000000",
    PaginationDisabled: "#d5dbed7d",
    White: "#ffffff",
    GithubBg: "#ffffff",
    Load: "#4198FF",
    LoadBg: "#083F5F",
    Like: "#fe5d5d",
    Blackwhite: "#ffffff",
    PageNum: "#BDE7FF",
    ActivePage: "#83CCFF",
    TabText: "#083F5F",
    Title: "#BDE7FF",
    SubTitle: "#567587",
    ImageText: "#DCF2FF",
    UnLike: "#E1E1E1",
    BlogAuthor: "#A5D2EB",
    BlogDate: "#083F5F",
    FilterHeading: "#BDE7FF",
    Profileicon: "#ffffff",
    Dashboard: "#083F5F",
    PastRole: "#1B4C7F",
    PopUpText: "#BDE7FF",
    Shadow:
      " 0px 100px 80px rgba(0, 0, 0, 0.18), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.129394), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.107299), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.09), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0727007), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0506062);",
    ScrollBg: "#06141C",
    ScrollThumbBg: "#123A50",
    PseudoProfile: "#418df9",
    EmptyImg: "rgb(0, 37, 56, 0.06)",
    RegButtonBg: "#FFB13D",
    RegButton: "#ffffff",
    Card: "#66C0FF",
    TagButton: "#e1ecf4",
    TextLink: "#0074cc",
    Dot: "#ffffff",
    Closed: "#772a2a",
    unseenNotification: "#083F5F",
  },
  MediaQueries: MediaQueries,
  Fonts: Fonts,
};
