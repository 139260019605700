import React from "react";
import styled from "styled-components";

const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0px 17px;
  width: 100%;
  max-width: 135px;

  &.short {
    max-width: 100px;
  }

  &.long {
    max-width: 190px;
    width: 190px;
    margin: 0 0 0 17px;
    @media ${(props) => props.theme.MediaQueries.m.query} {
      font-size: 19.2px;
      max-width: 225px;
      width: 225px;
    }
  }
`;

const SliderName = styled.p`
  color: #4d565b;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-weight: 600;
  font-size: 13.6px;
  margin: 0;
`;

const Slider = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  display: flex;
  cursor: pointer;
  width: 25px;
  height: 11px;
  border-radius: 100px;
  background-color: rgb(196, 196, 196, 0.5);
  position: relative;
  transition: background-color 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: -1.75px;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 25px;
    transition: 0.2s;
    background-color: #ffff;
    box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  &:checked {
    background-color: rgb(255, 194, 103, 0.5);
    &:before {
      background-color: #ffc267;
      left: 100%;
      transform: translateX(-100%);
    }
  }
`;

const Toggle = ({ onChange, Tag, long, short,checked }) => {
  return (
    <ToggleDiv className={`${long ? "long" : short ? "short" : null}`}>
      <SliderName>{Tag}</SliderName>
      <Slider onChange={onChange} checked={checked}></Slider>
    </ToggleDiv>
  );
};

export default Toggle;
