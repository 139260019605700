import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  photo: "",
  isLoggedIn: false,
  accessToken: "",
  email: "",
  loader: true,
  role: "All",
  slug: "",
};

export const authorizationSlice = createSlice({
  name: "authorization", 
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.name = action.payload.data.name;
      state.photo = action.payload.data.profile;
      state.accessToken = action.payload.accessToken;
      state.email = action.payload.data.email;
      state.role = action.payload.data.roleModel;
      state.eventHead = action.payload.data.isEventHead;
      if(action.payload.data.roleModel !== "Council" && action.payload.data.roleModel !== "Railway" && action.payload.data.roleModel !== "RegDesk" && action.payload.data.roleModel !== "AlumniAdmin") state.slug = action.payload.data.role.slug;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.name = null;
      state.photo = null;
      state.accessToken = null;
      state.email = null;
      state.role = "All";
      state.slug = null;
    },
    loaderTrue: (state) => {
      state.loader = true;
    },
    loaderFalse: (state) => {
      state.loader = false;
    },
  },
});

export const { login, logout, loaderTrue, loaderFalse } =
  authorizationSlice.actions;

export default authorizationSlice.reducer;
