import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ authRole, children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  return isLoggedIn && authRole.includes(role) ? (
    children
  ) : role === "Student" ? (
    <Navigate to="/events" />
  ) : role === "Team" ? (
    <Navigate to="/dashboard" />
  ) : role === "Council" ? (
    <Navigate to="/reports" />
  ) : role === "Railway" ? (
    <Navigate to="/railway/admin" />
  ) : role === "RegDesk" ? (
    <Navigate to="/tml/regdesk" />
  ) : role === "All" ? (
    <Navigate to="/" />
  ) : role === "Alumni" ? (
    <Navigate to="/alumni/dashboard"/>
  ) : role === "AlumniAdmin" ? (
    <Navigate to="/alumniAdmin" />
  ) : null;
};

const NotAccessibleRoute = ({ children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  return isLoggedIn ? (
    role === "Student" ? (
      <Navigate to="/events" />
    ) : role === "Team" ? (
      <Navigate to="/dashboard" />
    ) : role === "Council" ? (
      <Navigate to="/reports" />
    ) : role === "Railway" ? (
      <Navigate to="/railway/admin" />
    ) : role === "RegDesk" ? (
      <Navigate to="/tml/regdesk" />
    ) : role === "Alumni" ? (
      <Navigate to="/alumni/dashboard" />
    ) : role === "AlumniAdmin" ? (
      <Navigate to="/alumniAdmin" />
    ): null
  ) : (
    children
  );
};

export { ProtectedRoute, NotAccessibleRoute };
