import axiosJWT from "./axiosJWT";
const axios = require("axios").default;

export const alumniRegistration = async (form) => {
  return await axios({
    method: "POST",
    url: `/api/v1/alumni`,
    data: form,
    headers: { Content_Type: "application/json" },
  });
};

export const updateAlumni = async (form) => {
  return await axiosJWT({
    method: "PUT",
    url: `/api/v1/alumni/update`,
    data: form,
  });
};

export const createQuery = async (form) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/alumni/createQuery/`,
    data: form,
  });
};

export const createNotification = async (queryData) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/notification/createNotification/?feature=${"Query"}`,
    data : queryData
  });
};

export const updateQuery = async (form, slug) => {
  return await axiosJWT({
    method: "PUT",
    url: `/api/v1/alumni/updateQuery/${slug}`,
    data: form,
  });
};

export const getProfile = async (slug) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/alumni/profile/${slug}`,
  });
};

export const getAllAlumni = async (page, limit, branch, passOutYear) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/alumni/getAllAlumni/?page=${page}&limit=${limit}&passOutYear=${passOutYear}&branch=${branch}`,
  });
};

export const getMyQuery = async (page, limit, name) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/alumni/myQuery/?page=${page}&limit=${limit}&name=${name}`,
  });
};

export const getAQuery = async (slug, filter) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/alumni/singleQuery/${slug}?filter=${filter}`,
  });
};

export const getAllQuery = async (name, domain, page, limit) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/alumni/getQuery/?name=${name}&domain=${domain}&page=${page}&limit=${limit}`,
  });
};



export const deleteQuery = async (slug) => {
  return await axiosJWT({
    method: "DELETE",
    url: `/api/v1/alumni/queryDelete/${slug}`,
  });
};
 
export const comments = async (form, slug) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/alumni/comment/${slug}`,
    data: form,
  });
};

export const upVoteComment = async (form, slug) => {
  return await axiosJWT({
    method: "PUT",
    url: `/api/v1/alumni/upVote/${slug}`,
    data: form,
  });
};

export const reply = async (form, slug) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/alumni/reply/${slug}`,
    data: form,
  });
};

export const deleteComment = async (form, slug) => {
  return await axiosJWT({
    method: "DELETE",
    url: `/api/v1/alumni/deleteComment/${slug}`,
    data: form,
  });
};

export const deleteReply = async (form, slug) => {
  return await axiosJWT({
    method: "DELETE",
    url: `/api/v1/alumni/deleteReply/${slug}`,
    data: form,
  });
};

export const deleteNotification = async (slug) => {
  return await axiosJWT({
    method: "DELETE",
    url: `/api/v1/notification/${slug}`,
  });
};
