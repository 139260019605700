import React, { useState } from "react";
import styled from "styled-components";
// import BlogsBackground from "../../Images/blogs-background.png";
import BlueImg from "../../Images/Jumbotron Blue.png";
import ExploreImg from "../../Images/ExploreImg.jpg";
import TeamsImg from "../../Images/TeamsImg.jpg";
import BlogsImg from "../../Images/BlogsImg.jpg";
import RailwayImg from "../../Images/RailwayImg.jpg";
import AnnounceImg from "../../Images/AnnounceImg.jpg";
import EventsImg from "../../Images/EventsImg.jpg";
import TMLImg from "../../Images/JumbotronViolet.png";
import AlumniImg from "../../Images/CogBg.png";
import DiwaliImg from "../../Images/DiwaliImg.jpg";
import { Link } from "react-router-dom";
import * as ROUTES from "../../Constants/routes";
import FormImage from "../../Images/FormImage.png"
import { getLeaderboard, uploadExcel } from "../../Services/tml";
import { toast } from "react-toastify";
import Loader from "../../Components/CommonComponents/Loader";
import RailwayDownload from "../RailwayDownload";
import { useSelector } from "react-redux";
import { DiwaliTheme } from "../../Theme";
const formData = require("form-data");

const JumbotronContainer = styled.div`
  /* height: 16.5em; */
  margin-bottom: 50px;
  width: 100%;
  border-radius: 0 0 16px 16px;
  background-repeat: no-repeat;
  background-size: cover;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    /* height: 18.5em; */
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 360px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 408px;
    border-radius: 0 0 32px 32px;
    &.disabled {
      height: 320px;
    }
  }
`;

const JumbotronContent = styled.div`
  padding: 48px 0 48px;
  text-align: center;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding-top: 128px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-top: 144px;
  }
`;

const HeadingBody = styled.p`
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: #ffffff;
  font-size: 12.8px;
  font-weight: 700;
  text-align: center;
  padding: 0 32px 0;
  margin-bottom: 30px;
  margin-top: 10px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    font-size: 16px;
    padding: 0 56px 0;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 18px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 22px;
    padding: 0 80px 0;
    margin-bottom: 50px;
  }
`;

const HeadingLabel = styled.h1`
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: ${(props) => props.theme.Colors.White};
  font-size: 22.4px;
  font-weight: 700;
  text-align: center;
  padding: 0 24px 0;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    font-size: 25.6px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 28.8px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 45px;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    justify-content: flex-end;
    margin-top: 50px;
  }
`;

const ButtonRed = styled.button`
  cursor: pointer;
  margin-bottom: 10px;
  height: 40px;
  padding: 11px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.12);
  border: 1.5px solid rgba(255, 255, 255, 0.42);
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    margin: 0 10px 0 0;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 50px;
    margin-right: 25px;
  }
`;

const ButtonYellow = styled.button`
  position: sticky;
  top: 60px;
  cursor: pointer;
  height: 40px;
  padding: 11px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.12);
  border: 1.5px solid rgba(255, 255, 255, 0.42);
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 50px;
    margin-right: 25px;
  }
`;

const ButtonForms = styled.button`
  position: relative;
  top: 20px;
  cursor: pointer;
  height: 40px;
  padding: 11px;
  color: #ffffff;
  background:#ff9f0f ;
  border: none;
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 50px;
    margin-right: 25px;
  }
`;
const ButtonBlue = styled.button`
  cursor: pointer;
  height: 40px;
  padding: 11px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.12);
  border: 1.5px solid rgba(255, 255, 255, 0.42);
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 50px;
    margin-right: 25px;
  }
`;

const DownloadYellow = styled.div`
  margin-top: 20px;
  cursor: pointer;
  height: 40px;
  padding: 11px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.12);
  border: 1.5px solid rgba(255, 255, 255, 0.42);
  box-sizing: border-box;
  border-radius: 60px;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 50px;
    margin-right: 25px;
    margin-top: 0;
  }
`;

const Form = styled.form`
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 16px 0;
`;

const SearchBox = styled.div`
  position: relative;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    margin-bottom: 10px;
  }
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    margin-bottom: 10px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    margin-bottom: 0px;
  }
`;

const Input = styled.input`
  max-width: 320px;
  height: 48px;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  padding-left: 20.8px;
  padding-right: 90px;
  width: 256px;
  color: ${(props) => props.theme.Colors.HeaderText};
  background-color: transparent;
  border: 1px solid #ffffff;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    width: 256px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 288px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 320px;
  }
  &:focus {
    border: 1px solid #ffffff;
    outline: none;
  }
  &::placeholder {
    color: #ffffff;
  }
`;

const FileInput = styled.input`
  max-width: 200px;
  height: 30px;
  border: none;
  font-size: 14px;
  width: 200px;
  color: white;
  background-color: transparent;
  border: none;
  &:focus {
    border: 1px solid #ffffff;
    outline: none;
  }
  &::placeholder {
    color: #ffffff;
  }
`;

const Button = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.Colors.White};
  border-radius: 40px;
  padding: 0.3;
  border: none;
  max-width: 160px;
  width: 80px;
  height: 35.2px;
  position: absolute;
  top: 14%;
  right: 2%;
  font-size: 16px;
  font-weight: 500;
  background-color: #ff9f0f;
  & box-icon {
    fill: ${(props) => props.theme.Colors.White};
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    flex-direction: row;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    flex-direction: row;
    align-items: center;
  }
`;

const Jumbotron = ({
  label,
  body,
  placeholder,
  disabled,
  ButtonTxt,
  BtnRed,
  BtnYellow,
  BtnForm,
  BtnBlue,
  BtnQuery,
  setSearch,
  searchField,
  downYellow,
  downloadData,
  blogs,
  Cyan,
  Blue,
  Green,
  teams,
  explore,
  events,
  Orange,
  announce,
  forms,
  railway,
  Alumni,
  tml,
  Leaderboard,
  // onClick,
  // uploadFile,
  // setexcelFile
}) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadModel, setModel] = useState(false);
  const user = useSelector((state) => state.user);
  const [excelFile, setexcelFile] = useState([]);
  let form = new formData();

  const submitFile = (e) => {
    setexcelFile(e.target.files[0]);
  };
  form.set("file", excelFile);

  const uploadFile = () => {
    if (!excelFile) {
      toast.warn("Please upload an excel sheet");
      return false;
    }
    setLoading(true);
    uploadExcel(form)
      .then((result) => {
        setLoading(false);
        toast.success(result.data.message);
      })
      .catch((err) => {
        setLoading(false);
        toast.warn(err.response.data.message);
      });
  };

  return (
    <>
      {loading && <Loader />}
      {downloadModel && <RailwayDownload setModel={setModel} />}
      <JumbotronContainer
        className={`${disabled ? "disabled" : null}`}
        style={ 
          {
            backgroundImage: blogs
            ? `url(${BlogsImg})`
            : Blue
            ? `url(${BlueImg})`
            : events
            ? `url(${EventsImg})`
            : announce
            ? `url(${AnnounceImg})`
            : explore
            ? `url(${ExploreImg})`
            : railway
            ? `url(${RailwayImg})`
            : teams
            ? `url(${TeamsImg})`
            : tml
            ? `url(${TMLImg})`
            : Alumni
            ? `url(${AlumniImg})`
            : forms
            ? `url(${FormImage})`
            :null
        }
      }
      >
        <JumbotronContent>
          <HeadingLabel>{label}</HeadingLabel>
          <HeadingBody> {body}</HeadingBody>
          {!disabled && (
            <Form>
              <SearchBox>
                <Input
                  placeholder={placeholder}
                  name={searchField}
                  onChange={(e) => {
                    setData(e.target.value);
                  }}
                />
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearch(data);
                  }}
                >
                  Search
                  {/* <box-icon name="search-alt-2"></box-icon> */}
                </Button>
              </SearchBox>
            </Form>
          )}
         
          <BtnGroup>
            {BtnRed && (
              <FlexDiv>
                <Link to={ROUTES.CREATEEVENT}>
                  <ButtonRed>
                    <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                    &nbsp;
                    {ButtonTxt}
                  </ButtonRed>
                </Link>
                <Link to={ROUTES.CREATETMLEVENT}>
                  <ButtonRed>
                    <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                    &nbsp; Add TML Event
                  </ButtonRed>
                </Link>
              </FlexDiv>
            )}

            {BtnYellow &&
              (user.role === "Alumni" ? (
                <Link to={ROUTES.ANNOUNCEINTERNSHIP}>
                  <ButtonYellow>
                    <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                    &nbsp;
                    {ButtonTxt}
                  </ButtonYellow>
                </Link>
              ) : (
                <Link to={ROUTES.CREATEANNOUNCEMENT}>
                  <ButtonYellow>
                    <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                    &nbsp;
                    {ButtonTxt}
                  </ButtonYellow>
                </Link>
              ))}

            {BtnBlue && (
              <Link to={ROUTES.CREATEBLOG}>
                <ButtonBlue>
                  <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                  &nbsp;
                  {ButtonTxt}
                </ButtonBlue>
              </Link>
            )}
            
            {BtnForm && (
              
              <Link to={ROUTES.REGISTRATION}>
                <br></br>
                <ButtonForms>
                  <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                  &nbsp;
                  {ButtonTxt}
                </ButtonForms>
              </Link>
            )}


            {BtnQuery && (
              <Link to={ROUTES.CREATEQUERY}>
                <ButtonBlue>
                  <box-icon name="plus" color="#ffffff"></box-icon> &nbsp;
                  &nbsp;
                  {ButtonTxt}
                </ButtonBlue>
              </Link>
            )}

            {downYellow && (
              <DownloadYellow onClick={() => setModel(true)}>
                <box-icon name="plus" color="#ffffff"></box-icon> &nbsp; &nbsp;
                {ButtonTxt}
              </DownloadYellow>
            )}

            {Leaderboard && (
              <FlexWrap>
                <FileInput
                  placeholder="Upload Excel Sheet"
                  type="file"
                  name="leaderboard"
                  onChange={(e) => {
                    setexcelFile(e.target.files[0]);
                  }}
                />
                <DownloadYellow
                  onClick={() => {
                    uploadFile();
                  }}
                  type="POST"
                >
                  <box-icon name="upload" color="#ffffff"></box-icon> &nbsp;
                  &nbsp;
                  {ButtonTxt}
                </DownloadYellow>
              </FlexWrap>
            )}
          </BtnGroup>
        </JumbotronContent>
      </JumbotronContainer>
    </>
  );
};

export default Jumbotron;
