import styled from "styled-components";

const StyledIcon = styled.a`
  & box-icon {
    fill: ${(props) => props.theme.Colors.FooterTextPrimary};
  }
`;

const Icon = ({ link, iconName, iconType , page }) => (
  <>
    <StyledIcon href={link} target="_blank" rel="noopener noreferrer" aria-label={iconName} className={page}>
      <box-icon type={iconType} name={iconName} ></box-icon>
    </StyledIcon>
  </>
);

export default Icon;
