import * as ROUTES from "./routes";

export const SIDE_NAV = [
  {
    to: ROUTES.EVENTREPORTS,
    icon: "report",
    name: "Event Reports",
    role: ["Council"],
  },
  {
    to: ROUTES.TEAMDASHBOARD,
    icon: "dashboard",
    name: "Dashboard",
    role: ["Team"],
  },
  {
    to: ROUTES.ALUMNIDASHBOARD,
    icon: "dashboard",
    name: "Dashboard",
    role: ["Alumni"],
  },
  {
    to: ROUTES.ALUMNIADMINDASHBOARD,
    icon: "dashboard",
    name: "dashboard",
    role: ["AlumniAdmin"],
  },
  {
    to: ROUTES.RAILWAYADMIN,
    icon: "train",
    name: "Railway",
    role: ["Railway"],
  },
  {
    to: ROUTES.TML_REGDESKDASHBOARD,
    icon: "wallet",
    name: "RegDesk",
    role: ["RegDesk"],
  },
  {
    to: ROUTES.TML_LANDING_PAGE,
    icon: "camera-movie",
    name: "TML",
    role: ["Student", "Team", "All"],
  },
  {
    to: ROUTES.TML_EVENTHEADDASH,
    icon: "dashboard",
    name: "Event Dashboard",
    role: ["EventHead"],
  },
  {
    to: ROUTES.EVENTS,
    icon: "calendar-alt",
    name: "Events",
    role: ["Student", "Team", "All", "Council", "Alumni"],
  },
  {
    to: ROUTES.EXPLOREPAGE,
    icon: "user",
    name: "Explore",
    role: ["Student", "Team", "Alumni"],
  },
  {
    to: ROUTES.ALLQUERIES,
    icon: "conversation",
    name: "Forum",
    role: ["Student", "Alumni"],
  },
  {
    to: ROUTES.ANNOUNCEMENTS,
    icon: "megaphone",
    name: "Announcements",
    role: ["Student", "Team", "Council", "Alumni"],
  },
  {
    to: ROUTES.TEAMS,
    icon: "group",
    name: "Teams",
    role: ["Student", "Team", "All", "Council", "Alumni"],
  },
  {
    to: ROUTES.NOTES,
    icon: "bookmark",
    name: "Notes",
    role: ["Student"],
  },
  {
    to: ROUTES.BLOGS_ALL,
    icon: "book",
    name: "Blogs",
    role: ["Student", "Team", "All", "Alumni"],
  },
  {
    to: ROUTES.RAILWAYUSER,
    icon: "train",
    name: "Railway",
    role: ["Student"],
  },
];
