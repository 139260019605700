import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
      <App />
      {/* <Notifications /> */}
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

