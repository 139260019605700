import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NavTogglerImage from "../../Images/CommonComponents/NavToggler.svg";
import PnCLogo from "../../Images/PnC logo.png";
import DarkModeToggle from "react-dark-mode-toggle";
import { NAV } from "../../Constants/navbar";
import { Link } from "react-router-dom";
import * as ROUTES from "../../Constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/authorization";
import { logoutRoute } from "../../Services/authorization";
import { useGoogleLogout } from "react-google-login";
import { toast } from "react-toastify";
import NotificationBadge from "react-notification-badge";
import NotificationFeature from "../CommonComponents/Notification";
import jwt_decode from "jwt-decode";
import { getNotifications } from "../../Services/notification";
import Loader from "./Loader";
const { REACT_APP_GOOGLE, REACT_APP_GOOGLE_DEV, REACT_ENV } = process.env;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  /* box-shadow: 0px 100px 80px rgba(206, 227, 250, 0.18),
    0px 41.7776px 33.4221px rgba(206, 227, 250, 0.129394),
    0px 22.3363px 17.869px rgba(206, 227, 250, 0.107299),
    0px 12.5216px 10.0172px rgba(206, 227, 250, 0.09),
    0px 6.6501px 5.32008px rgba(206, 227, 250, 0.0727007),
    0px 2.76726px 2.21381px rgba(206, 227, 250, 0.0506062); */
  padding: 0 16px;
  z-index: 100;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: space-between;
`;

const HeaderLogo = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.Colors.HeaderText};
  font-weight: 600;
  font-size: 20.8px;
  display: block;
  font-family: ${(props) => props.theme.Fonts.Inter};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 16px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ThemeToggler = styled(DarkModeToggle)`
  margin-right: 3.2px;
`;

const HeaderImg = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: ${(props) => (props.nav ? "11.2px" : null)};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 40px;
    height: 40px;
  }
`;

const HeaderToggle = styled.img`
  width: 23px;
  height: 23px;
  cursor: pointer;
  margin-right: 11.2px;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    display: none;
  }
`;

// Notifications

const NotificationIcon = styled.div`
  cursor: pointer;
  margin: 2px 10px;
  & box-icon {
    width: 27.3px;
    height: auto;
    // fill: ${(props) => props.theme.Colors.LabelText};
    fill: ${(props) => props.theme.Colors.HeaderText};
  }
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    & box-icon {
      width: 20.3px;
    }
  }
`;

const OptionListDiv = styled.ul`
  width: 250px;
  position: absolute;
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  display: none;
  text-align: left;
  margin-left: -200px;
  /* padding-bottom: 10px; */
  transition: all 0.1s;
  box-shadow: ${(props) => props.theme.Colors.Shadow};
`;

const Options = styled.div`
  cursor: pointer;
  position: relative;
  & box-icon {
    font-size: 12.8px;
  }
  &:hover ${OptionListDiv} {
    display: block;
  }
`;

const OptionList = styled.li`
  color: ${(props) => props.theme.Colors.LabelText};
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
    props.main ? "" : props.theme.Colors.PlaceholderBorder};
    color: ${(props) => props.theme.Colors.HeaderTextNav};
  }
  & box-icon {
    height: 18px;
    fill: ${(props) => props.theme.Colors.LabelText};
  }
  &:hover box-icon {
    fill: ${(props) => props.theme.Colors.HeaderTextNav};
  }
  &:active {
    color: ${(props) => props.theme.Colors.HeaderTextNav};
  }
  &:active box-icon {
    fill: ${(props) => props.theme.Colors.HeaderTextNav};
  }

  &.delete box-icon {
    fill: #ff5d61;
  }
`;

const OptionContent = styled.a`
  padding-left: 12px;
  height: ${(props) => (props.main ? "40px" : "16px")};
  display: ${(props) => (props.main ? "block" : "flex")};
  align-items: center;

  &.delete {
    color: #ff5d61;
  }
`;

const SubMenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const MenuTitle = styled.div`
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: ${(props) =>
    props.Name
      ? props.theme.Colors.HeaderTextNav
      : props.theme.Colors.BodyText};
  font-weight: ${(props) => (props.Name ? "600" : "400")};
  font-size: ${(props) => (props.Name ? "18px" : "14px")};
`;

const PseudoProfile = styled.div`
  display: inline-block;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: 600;
  line-height: 35px;
  text-align: center;
  background: ${(props) => props.theme.Colors.PseudoProfile};
  color: white;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-left: ${(props) => (props.nav ? "11.2px" : null)};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 40px;
    height: 40px;
    font-size: 22.4px;
    line-height: 40px;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: auto;
  aspect-ratio: 1;
  margin-right: 10px;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    width: 30px;
  }
`;

const Navbar = ({ isDarkTheme, changeTheme, toggleNav, msalInstance, isDiwaliTheme }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 4;
  const user = useSelector((state) => state.user);
  const userId = user.isLoggedIn ? jwt_decode(user.accessToken).userId : null;
  const userRole = user.role;

  const validateEduId = (url) => {
    let pattern = /^[a-zA-Z0-9._%+-]+@gst\.sies\.edu\.in$/;
    return pattern.test(url);
  };

  const clientId =
    "762768919343-nupuvkq6iqso8gh92esvpa1dl6rgjmd4.apps.googleusercontent.com";
  const cookiePolicy = "single_host_origin";

  const onLogoutSuccess = () => {
    dispatch(logout());
    navigate(ROUTES.LANDINGPAGE, { replace: true });
  };

  const onFailure = () => {
    if (user.isLoggedIn) toast.warn("Can't log out, please try again");
  };
  const { signOut } = useGoogleLogout({
    clientId,
    cookiePolicy,
    onLogoutSuccess,
    onFailure,
  });

  const logoutFunc = () => {
    logoutRoute()
      .then(() => {
        if (validateEduId(user.email)) {
          msalInstance.clearCache();
          msalInstance.logout();
          dispatch(logout());
          navigate(ROUTES.LANDINGPAGE, { replace: true });
        } else {
          signOut();
        }
      })
      .catch((err) => {
        toast.warn("Can't log out, please try again or refresh the page");
      });
  };
  
  const [isHovered, setIsHovered] = useState(false);

  const handleCLick = () => {
    setIsHovered(true);
  };
  let [loading, setLoading] = useState(false);
  const [unseenCount, setunseenCount] = useState(0);

  useEffect(() => {
    getNotifications()
      .then((result) => {
        setunseenCount(result.data.data.unseenCount);
      })
      .catch((error) => {
        // console.log(error);
        setunseenCount(0);
      });
  }, [isHovered]);

  return (
    <>
      {loading && <Loader />}
      <Header>
        <HeaderContainer>
          <SubHeader>
            <HeaderToggle
              src={NavTogglerImage}
              onClick={toggleNav}
              alt="nav-toggler"
            />
            <Logo src={PnCLogo} alt="Logo" />
            <HeaderLogo
              onClick={() =>
                user.role === "Student"
                  ? navigate(ROUTES.EVENTS)
                  : user.role === "Team"
                    ? navigate(ROUTES.TEAMDASHBOARD)
                    : user.role === "Council"
                      ? navigate(ROUTES.EVENTREPORTS)
                      : user.role === "Railway"
                        ? navigate(ROUTES.RAILWAYADMIN)
                        : user.role === "All"
                          ? navigate(ROUTES.LANDINGPAGE)
                          : user.role === "Alumni"
                            ? navigate(ROUTES.ALUMNIDASHBOARD)
                            : null
              }
            >
              Portal 2.0
            </HeaderLogo>
          </SubHeader>
          <SubHeader>
            {(user.isLoggedIn && user.role != "AlumniAdmin") && (
              <NotificationIcon
                onClick={handleCLick}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <NotificationBadge
                  count={unseenCount}
                  style={{ top: "-8px", right: "-8px" }}
                />

                <box-icon type="solid" name="bell"></box-icon>
                {isHovered && <NotificationFeature />}
              </NotificationIcon>
            )}
            {!isDiwaliTheme &&
              <ThemeToggler
                onChange={changeTheme}
                checked={isDarkTheme}
                size={38}
                shadows={false}
              />
            }

            {user.isLoggedIn && (
              <Options>
                {user.photo ? (
                  <HeaderImg nav src={user.photo} />
                ) : (
                  <>
                    <PseudoProfile
                      nav
                      dangerouslySetInnerHTML={{
                        __html: user.name.split("")[0],
                      }}
                    />
                  </>
                )}
                <OptionListDiv>
                  <OptionList
                    main
                    onClick={() => {
                      if (user.role === "Team")
                        navigate(
                          `${ROUTES.EACHTEAM.split(":")[0]}${user.slug}`,
                          {
                            replace: false,
                          }
                        );
                      else if (user.role === "Student")
                        navigate(
                          `${ROUTES.PROFILE.split(":")[0]}${user.slug}`,
                          {
                            replace: false,
                          }
                        );
                      else if (user.role === "Alumni")
                        navigate(
                          `${ROUTES.ALUMNIPROFILE.split(":")[0]}${user.slug}`,
                          {
                            replace: false,
                          }
                        );
                    }}
                  >
                    {user.photo ? (
                      <HeaderImg src={user.photo} />
                    ) : (
                      <>
                        <PseudoProfile
                          dangerouslySetInnerHTML={{
                            __html: user.name.split("")[0],
                          }}
                        />
                      </>
                    )}
                    <SubMenuDiv>
                      <OptionContent main>
                        <MenuTitle
                          Name
                          id="firstName"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {user.name}
                        </MenuTitle>
                        <MenuTitle>{user.email}</MenuTitle>
                      </OptionContent>
                    </SubMenuDiv>
                  </OptionList>

                  {NAV.map((item, index) => {
                    if (item.role.includes(user.role)) {
                      return (
                        <Link to={item.to} key={index}>
                          <OptionList activeClassName="active">
                            <box-icon name={item.icon} type="solid"></box-icon>
                            <OptionContent>{item.name}</OptionContent>
                          </OptionList>
                        </Link>
                      );
                    }
                  })}

                  {user.email === "sports@siesgst.ac.in" && (
                    <Link to={ROUTES.MYLEADERBOARD}>
                      <OptionList activeClassName="active">
                        <box-icon name="medal" type="solid"></box-icon>
                        <OptionContent>Lakshya Dashboard</OptionContent>
                      </OptionList>
                    </Link>
                  )}

                  {user.email === "theliteraryteam@siesgst.ac.in" ||
                    (user.name === "Literary Team" && (
                      <Link to={ROUTES.EVENTREPORTS}>
                        <OptionList activeClassName="active">
                          <box-icon name="report" type="solid"></box-icon>
                          <OptionContent>Event Reports</OptionContent>
                        </OptionList>
                      </Link>
                    ))}

                  <OptionList
                    activeClassName="active"
                    className="delete"
                    onClick={() => {
                      logoutFunc();
                    }}
                  >
                    <box-icon name="log-out" type="regular"></box-icon>
                    <OptionContent className="delete">Logout</OptionContent>
                  </OptionList>
                </OptionListDiv>
              </Options>
            )}
          </SubHeader>
        </HeaderContainer>
      </Header>
    </>
  );
};

export default Navbar;
