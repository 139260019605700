import styled from "styled-components";
import Icon from "../Icon";
import * as ROUTES from "../../Constants/routes";
import { useNavigate , useLocation } from "react-router-dom";
import FooterBg from "../../Images/FooterBg.svg";

const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.Colors.FooterBg};
  padding: 20px;
  // background: url(${FooterBg});
`;

const FooterFlex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

const FooterText = styled.a`
  cursor: pointer;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-size: 14.4px;
  font-weight: bold;
  text-align: center;
  margin: 0 32px;
  color: ${(props) => props.theme.Colors.FooterTextPrimary};
`;

const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20.8px;
  margin: 0 8px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    padding: 16px 56px;
    &:after {
      font-family: ${(props) => props.theme.Fonts.Poppins};
      font-weight: bold;
      content: "- follow us";
      font-size: 12.8px;
      width: max-content;
      color: ${(props) => props.theme.Colors.FooterTextSecondary};
    }
  }
`;

const CopyRight = styled.p`
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-weight: 500;
  font-size: 12.8px;
  color: ${(props) => props.theme.Colors.FooterTextSecondary};
  text-align: center;
  margin-top: 32px;
  cursor: pointer;
`;
// home variable is only for multi-theme used in GlobalStyles.js, EventsCard.js, Icon.js, TeamDashboard.js 

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <FooterContainer>
        <FooterFlex>
          <FooterText onClick={() => navigate(ROUTES.DEVELOPERSPAGE)}>
            Developed with ❤ by the Technical Team of SIES GST
          </FooterText>

          <IconDiv>
            <Icon 
              page = {location.pathname === ROUTES.LANDINGPAGE || location.pathname === ROUTES.TML_LANDING_PAGE ? "landingpage" : ""}
              iconName="linkedin"
              iconType="logo"
              link="https://www.linkedin.com/company/technical-team-sies-gst/mycompany/"
            />
            <Icon
              page = {location.pathname === ROUTES.LANDINGPAGE || location.pathname === ROUTES.TML_LANDING_PAGE ? "landingpage" : ""}
              iconName="instagram"
              iconType="logo"
              link="https://www.instagram.com/technicalteam_siesgst/"
            />
            <Icon
              page = {location.pathname === ROUTES.LANDINGPAGE || location.pathname === ROUTES.TML_LANDING_PAGE ? "landingpage" : ""}
              iconName="github"
              iconType="logo"
              link="https://github.com/siesgst-tech"
            />
            <Icon 
              page = {location.pathname === ROUTES.LANDINGPAGE || location.pathname === ROUTES.TML_LANDING_PAGE ? "landingpage" : ""}
              iconName="facebook"
              iconType="logo"
              link="https://www.facebook.com/SIESGSTEvents"
            />
          </IconDiv>
        </FooterFlex>
        <CopyRight onClick={() => navigate(ROUTES.PRIVACY)}>
          Copyright &nbsp; © 2024-2025
        </CopyRight>
      </FooterContainer>
    </>
  );
};

export default Footer;
