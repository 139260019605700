const axios = require("axios").default;

export const userRegister = (data) => {
  return axios({
    method: "POST",
    url: "/api/v1/auth/",
    data: {
      name: data.name,
      email: data.email,
      prn: data.prn,
      branch: data.branch,
      year: data.year,
      gender: data.gender,
      dob: data.dob,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const googleLogin = (googleData) => {
  return axios({
    method: "POST",
    url: "/api/v1/auth/google",
    data: { token: googleData.tokenId },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const outlookLogin = (outlookData) => {
  return axios({
    method: "POST",
    url: "/api/v1/auth/outlook",
    data: { token: outlookData.accessToken },
  });
};

export const refreshRoute = () => {
  return axios({
    method: "post",
    url: "/api/v1/auth/refresh",
  });
};

export const logoutRoute = () => {
  return axios({
    method: "GET",
    url: "/api/v1/auth/logout",
  });
};


