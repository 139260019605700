import React, { useState } from "react";
import styled from "styled-components";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "../Components/CommonComponents/Toggle";
import { toast } from "react-toastify";

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000094;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding: 64px 0 0 52px;
  }
`;

const PopupDiv = styled.div`
  box-shadow: ${(props) => props.theme.Colors.Shadow};
  z-index: 10;
  position: fixed;
  width: 100%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  background: ${(props) => props.theme.Colors.PageBg};
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 20px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    max-width: 400px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 600px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    justify-content: flex-end;
  }
`;
const Heading = styled.h1`
  margin-bottom: 30px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: ${(props) => props.theme.Colors.HeaderText};
  font-size: 20px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 24px;
  }
`;

const CancelButton = styled.button`
  cursor: pointer;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 12.8px;
  border: none;
  background: none;
  padding: 8px 24px;
  color: ${(props) => props.theme.Colors.BodyText};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
  }
`;

const DownloadButton = styled.a`
  cursor: pointer;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 12.8px;
  background: #ff9f0f;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 8px 24px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
  }
`;

const Label = styled.label`
  color: ${(props) => props.theme.Colors.LabelText};
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: 600;
  padding: 10px 0 7px 0;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding: 20px 0 7px 0;
  }
`;

const Input = styled.input`
  height: 50px;
  width: 100%;
  border: 0.7px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  font-family: ${(props) => props.theme.Fonts.Inter};
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.Colors.PlaceholderText};
  background-color: ${(props) => props.theme.Colors.PlaceholderBg};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
  width: 100%;
  max-width: 260px;
`;

const SliderName = styled.p`
  color: ${(props) => props.theme.Colors.LabelText};
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: 600;
  font-size: 13.6px;
  margin: 0;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
  }
`;

const Slider = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  display: flex;
  cursor: pointer;
  width: 25px;
  height: 11px;
  border-radius: 100px;
  background-color: rgb(196, 196, 196, 0.5);
  position: relative;
  transition: background-color 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: -1.75px;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 25px;
    transition: 0.2s;
    background-color: #ffff;
    box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  &:checked {
    background-color: rgb(255, 194, 103, 0.5);
    &:before {
      background-color: #ffc267;
      left: 100%;
      transform: translateX(-100%);
    }
  }
`;

const RailwayDownload = ({ setModel }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [disable, setDisable] = useState(false);

  const download = () => {
    if (disable || !(startDate === "" && endDate === "")) {
      window.location.href = `https://portal.siesgst.ac.in/api/v1/railway/download?startDate=${startDate}&endDate=${endDate}`;
      toast.success("Excel downloaded successfully");
      setModel(false);
    } else toast.warn("Enter start and end date");
  };

  return (
    <Modal>
      <PopupDiv>
        <Heading>Download Excel Sheet</Heading>
        <Form>
          <Label>Start Date</Label>
          <Datepicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            customInput={<Input />}
            placeholderText="Enter start date"
            disabled={disable}
          />

          <Label>End Date</Label>
          <Datepicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd/MM/yyyy"
            customInput={<Input />}
            placeholderText="Enter end date"
            disabled={disable}
          />
        </Form>

        <ToggleDiv>
          <SliderName>Download Whole Excel Sheet</SliderName>
          <Slider
            onChange={() => {
              setDisable(!disable);
              setStartDate("");
              setEndDate("");
            }}
          ></Slider>
        </ToggleDiv>

        <FlexDiv>
          <CancelButton onClick={() => setModel(false)}>Cancel</CancelButton>
          <DownloadButton onClick={download}>Download</DownloadButton>
        </FlexDiv>
      </PopupDiv>
    </Modal>
  );
};

export default RailwayDownload;
