import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Popup from "./Popup";
import { useSelector } from "react-redux";
import ImagePopup from "../Components/CommonComponents/ImagePopup";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import * as ROUTES from "../Constants/routes";
import { deleteQuery , deleteNotification} from "../Services/alumni";
// import {  } from "../Services/notification";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  position: relative;
  margin: 20px auto;
  margin-top: 0;
  height: auto;
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 15px;
  max-width: 800px;
  width: 90vw;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 48vw;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 58vw;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 65vw;
  }
`;

const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 20px;
  max-height: 360px;
  object-fit: cover;
`;

const CardText = styled.p`
  cursor: pointer;
  font-size: 20.8px;
  padding: 16px 34px 0 34px;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-weight: 700;
  color: ${(props) => props.theme.Colors.GithubBg};
  margin: 0px;
  // margin-top: 0.8rem;
  white-space: normal;
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    max-width: 50vw;
    padding: 16px 34px 0 34px;
    text-align: justify;
  }
`;

const Author = styled.p`
  cursor: pointer;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: normal;
  color: ${(props) => props.theme.Colors.BlogAuthor};
  padding-left: 11.2px;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 18px;
  }
`;

const Domain = styled.p`
  max-width: 100%;
  font-size: 16px;
  // margin-left: 32px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 400;
  display: inline-block;
  color: ${(props) => props.theme.Colors.BlogAuthor};
`;

const Closed = styled.div`
  border: 1px solid;
  border-radius: 23.5px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 500;
  background: ${(props) => props.theme.Colors.Closed};
  font-size: 14px;
  margin-right: 10px;
  padding: 3px 15px;
  margin-bottom: 0;
  color: #e94252;
`;

const Time = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 600;
  padding-right: 9.6px;
  padding-left: 26px;
  color: ${(props) => props.theme.Colors.LabelText};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-right: 22px;
  }
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    padding-left: 0px;
    &.disabled {
      padding-left: 10px;
    }
  }
`;

const OptionListDiv = styled.ul`
  position: relative;
  background-color: none;
  width: 177px;
  z-index: 0;
  visibility: hidden;
  text-align: left;
  margin-left: -160px;
  transition: all 0.1s;
  box-shadow: ${(props) => props.theme.Colors.Shadow};
`;

const Options = styled.div`
  &.disabled {
    display: none;
  }
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 30px;
  right: 20px;
  & box-icon {
    fill: ${(props) => props.theme.Colors.PlaceholderText};
    font-size: 12.8px;
  }
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    right: 10px;
  }
  @media ${(props) => props.theme.MediaQueries.mtl.query} {
    right: 10px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    top: 30px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    top: 40px;
  }

  &:hover ${OptionListDiv} {
    display: block;
    visibility: visible;
  }
`;

const OptionList = styled.li`
  position: relative;
  color: ${(props) => props.theme.Colors.LabelText};
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 18px;
  padding: 12.5px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  }
  & box-icon {
    height: 16px;
    width: auto;
    margin-right: 12px;
  }
  &:hover {
    background-color: ${(props) =>
      props.main ? "" : props.theme.Colors.PlaceholderBorder};
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  & box-icon {
    height: 18px;
    fill: ${(props) => props.theme.Colors.LabelText};
  }
  &:hover box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText};
  }
  &:active {
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  &:active box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText};
  }
  &.delete box-icon {
    fill: #ff5d61;
  }
`;

const OptionContent = styled.a`
  font-size: 12.8px;
  color: ${(props) => props.theme.Colors.HeaderText};
  /* &:hover {
    background-color: ${(props) =>
    props.main ? "" : props.theme.Colors.PlaceholderBorder};
    color: ${(props) => props.theme.Colors.HeaderText};
  } */
  &.delete {
    color: #ff5d61;
  }
`;

const Container = styled.div`
  display: flex;
  padding-left: 10px;
  // padding: 32px;
  &.disabled {
    display: none;
  }
`;

const DetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 7.2px 7.2px 0;
  margin-top: 8px;
  margin-left: 33px;
  justify-content: ${(props) =>
    props.domain ? "flex-start" : "space-between"};
`;

const PseudoProfile = styled.div`
  background: ${(props) => props.theme.Colors.PseudoProfile};
  display: inline-block;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: 600;
  line-height: 35px;
  text-align: center;
  color: white;
  width: 33px;
  height: 33px;
  border-radius: 50%;
`;

const CardBody = styled.p`
  font-family: ${(props) => props.theme.Fonts.Poppins};
  text-align: justify;
  word-break: break-all;
  color: ${(props) => props.theme.Colors.BodyText};
  font-size: 16px;
  padding: 0px 32px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 14px;
  }
`;

const ProfilePic = styled.img`
  object-fit: cover;
  cursor: pointer;
  width: 33px;
  height: 33px;
  top: 2225px;
  border-radius: 54.5px;
  &.developer {
    width: 100px;
    height: 100px;
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  width: 100%;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    grid-auto-flow: column;
    justify-content: start;
  }
  @media ${(props) => props.theme.MediaQueries.s.query} {
    grid-auto-flow: column;
    justify-content: start;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  &.disabled {
    justify-content: ${(props) => (props.space ? "space-between" : null)};
  }
  flex-wrap: wrap;
  align-items: center;
  padding: 7.2px;
  padding-bottom: ${(props) => (props.space ? "0" : null)};
  margin-left: ${(props) => (props.domain ? "15px" : "0")};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 17px;
    margin-top: ${(props) => (props.space ? "10px" : null)};
  }
`;

const Tagdiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: ${(props) => (props.none ? "0px" : "0px 12px")};
  max-width: 100%;
  height: fit-content;
  // margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
  }
`;

const Tag = styled.p`
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 23.5px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  padding: 3px 15px;
  margin-bottom: 0;
  color: ${(props) => props.theme.Colors.HeaderText};
`;

const ReadMore = styled.a`
  color: ${(props) => props.theme.Colors.Load};
  font-family: ${(props) => props.theme.Fonts.Inter};
  cursor: pointer;
  padding: 0 40px 15px 20px;
  float: right;
  font-size: 13px;
  display: ${(props) => (props.less ? "none" : "flex")};
  justify-content: flex-end;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 15px;
  }
`;

const QueryCard = ({
  name,
  image,
  domain,
  userData,
  title,
  time,
  slug,
  description,
  disabled,
  setValue,
  status,
}) => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const userId = user.isLoggedIn ? jwt_decode(user.accessToken).userId : null;
  const [buttonPop, setButtonPop] = useState(false);
  const [img, setImg] = useState("");
  const [model, setModel] = useState(false);
  const deleteQueries = () => {
    deleteQuery(slug)
      .then((result) => {
        toast.success(result.data.message);
        setButtonPop(false);
        setValue(true);
        if(result){
          deleteNotification(slug)
          .then((result) =>{
            if(result.data.success){
              console.log("Notification deleted")
            }
          })
        }
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
        setButtonPop(false);
      });
  };

  return (
    <>
      <Card>
        <FlexDiv space className={`${disabled ? "disabled" : "enabled"}`}>
          <FlexDiv>
            {userData.profile ? (
              <ProfilePic
                src={userData.profile}
                onClick={() =>
                  navigate(
                    `${ROUTES.PROFILE.split(":")[0]}${userData.role.slug}`
                  )
                }
              />
            ) : (
              <>
                <PseudoProfile
                  dangerouslySetInnerHTML={{
                    __html: userData.name.toUpperCase().split("")[0],
                  }}
                />
              </>
            )}
            <Author
              onClick={() =>
                navigate(`${ROUTES.PROFILE.split(":")[0]}${userData.role.slug}`)
              }
            >
              {name}
            </Author>
          </FlexDiv>
          <Time className={`${disabled ? "disabled" : "enabled"}`}>{time}</Time>
          {!status && (
            <DetailsDiv>
              <Tagdiv none>
                <Closed>Closed</Closed>
              </Tagdiv>
            </DetailsDiv>
          )}
          <Options className={`${disabled ? "disabled" : "enabled"}`}>
            <box-icon name="dots-vertical-rounded"></box-icon>
            <OptionListDiv>
              <OptionList
                onClick={() =>
                  navigate(`../alumni/updateQuery/${slug}`, {
                    replace: false,
                  })
                }
              >
                <box-icon name="pencil"></box-icon>
                <OptionContent>Edit Query</OptionContent>
              </OptionList>
              <OptionList
                type="submit"
                onClick={() => {
                  setButtonPop(true);
                }}
                className="delete"
              >
                <box-icon name="trash-alt" type="solid"></box-icon>
                <OptionContent className="delete">
                  Delete your Query
                </OptionContent>
              </OptionList>
            </OptionListDiv>
          </Options>

          {buttonPop && (
            <Popup
              setTrigger={setButtonPop}
              type="Query"
              name={title}
              deletes={deleteQueries}
            />
          )}
        </FlexDiv>
        <DetailsDiv domain>
          <Domain>Domain: </Domain>
          <Tagdiv start>
            {domain.map((domains, index) =>
              domains === "All" ? null : <Tag key={index}>{domains}</Tag>
            )}
          </Tagdiv>
        </DetailsDiv>
        <CardText
          onClick={() =>
            navigate(`../alumni/eachQuery/${slug}`, {
              replace: false,
            })
          }
        >
          {title}
        </CardText>

        {image && (
          <>
            {model && <ImagePopup setModel={setModel} Image={img} h500 />}
            <CardImage
              src={image}
              onClick={() => {
                setImg(image);
                setModel(true);
              }}
              alt="query cover"
            />
          </>
        )}
        <CardBody
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        ></CardBody>
        <ReadMore
          onClick={() => {
            navigate(`../alumni/eachQuery/${slug}`, {
              replace: false,
            });
          }}
        >
          Read more
        </ReadMore>
      </Card>
    </>
  );
};

export default QueryCard;
