//Landing Page Route
export const LANDINGPAGE = "/";

//Events Routes
export const EVENTS = "/events";
export const MYEVENT = "/events/my";
export const EACHEVENT = "/events/:slug";
export const CREATEEVENT = "/events/create";
export const UPDATEEVENTS = "/events/update/:slug";
export const EVENTREPORTS = "/reports";
export const ACADEMICCALENDAR = "/academiccalendar";

//Teams Routes
export const TEAMS = "/teams";
export const EACHTEAM = "/team/:slug";
export const EDITTEAMPROFILE = "/team/update/:slug";
export const TEAMDASHBOARD = "/dashboard";
export const CORETEAM = "/team/student-council";

//Student Routes
export const USERREGISTRAION = "/register";
export const PROFILE = "/profile/:slug";
export const EDITPROFILE = "/editprofile";
export const EXPLOREPAGE = "/explore";

//Notes Routes
export const NOTES = "/notes";
export const UPLOADNOTES = "/notes/create";

//Blogs Routes
export const BLOGS_ALL = "/blogs";
export const EACHBLOG = "/blog/:slug";
export const BLOGS_REVIEW = "/blogs/review";
export const MYBLOG = "/blogs/my";
export const CREATEBLOG = "/blogs/create";
export const UPDATEBLOG = "/blog/update/:slug";

//Announcements Routes
export const ANNOUNCEMENTS = "/announcements";
export const MYANNOUNCEMENT = "/announcement/my";
export const CREATEANNOUNCEMENT = "/announcements/create";
export const UPDATEANNOUNCEMENT = "/announcements/update/:slug";

//Railway Routes
export const RAILWAYUSER = "/railway";
export const RAILWAYADMIN = "/railway/admin";
export const PRINTCONCESSION = "/railway/admin/:id";

//Developers Page Route
export const DEVELOPERSPAGE = "/developers";

//Forms Routes
export const REGISTRATION = "/forms/create";
export const USERFORM = "/userform/:id";
export const ERRORPAGE = "/error";
export const FORMS = "/forms";
export const FORMTHANKYOU = "/formthankyou";
export const UPDATEFORM="/update/:id"

//Alumni Route
export const ALUMNI = "/alumni";
export const ALUMNIPROFILE = "/alumni/profile/:slug";
export const EDITALUMNIPROFILE = "/editalumniprofile";
export const ALUMNIREGISTRATION = "/alumni/registration";
export const CREATEQUERY = "/alumni/createQuery";
export const UPDATEQUERY = "/alumni/updateQuery/:slug";
export const EACHQUERY = "/alumni/eachQuery/:slug";
export const ALLQUERIES = "/gstforum";
export const MYQUERY = "/myquery";
export const ALUMNIDASHBOARD = "/alumni/dashboard";
export const ANNOUNCEINTERNSHIP = "/alumni/announcement/internship";
export const UPDATEINTERNSHIP = "/alumni/update/internship/:slug";
export const ALUMNIADMINDASHBOARD = "/alumniadmin";


//TML Routes
export const CREATETMLEVENT = "/events/tmlCreate";
export const TML_REGDESKDASHBOARD = "/tml/regdesk";
export const TML_REGDESKTABLE = "/tml/regdesk/:slug";
export const TML_LANDING_PAGE = "/tml/";
export const TML_SPONSORS = "/tml/sponsors";
export const TML_EVENTS = "/tml/events";
export const TML_EVENTHEADDASH = "/tml/eventhead";
export const TML_EACHEVENT= "/tml/:slug";
export const LEADERBOARD= "/tml/lakshya/scoreboard";
export const MYLEADERBOARD= "/tml/lakshya/scoreboard/dashboard";

//Privacy Route
export const PRIVACY = "/privacy";

//notification 
// export const NOTIFY = "/create-noti"