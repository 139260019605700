import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as ROUTES from "../Constants/routes";
import { getProfile } from "../Services/alumni";
import ImagePopup from "../Components/CommonComponents/ImagePopup";
import Loader from "../Components/CommonComponents/Loader";
import cover from "../Images/cover.png";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 768px;
    padding-left: 0;
    flex-direction: row;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 53px;
    max-width: 1400px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin-top: 64px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    position: sticky;
    top: 120px;
    max-width: 300px;
    margin-right: 20px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  // width: 340px;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    width: 576px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 768px;
    padding-top: 64px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 1100px;
  }
`;

const MainCardwrapper = styled.main`
  text-align: center;
  padding: 0;
  margin: 20px auto;
  padding-bottom: 20px;
  width: 300px;
  border-radius: 28px;
  border: 1.5px solid;
  border-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  &.events {
    overflow-y: auto;
    height: ${(props) => (props.height ? "auto" : "355px")};
    box-shadow: 0px 42px 193px rgba(0, 0, 0, 0.06),
      0px 17.1113px 34.9987px rgba(0, 0, 0, 0.0279534),
      0px 9.4682px 3.4133px rgba(0, 0, 0, 0.00917414);
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin-top: 0;
  }
`;

const ProfileCoverImg = styled.img`
  width: 100%;
  border-radius: 28px 28px 0 0;
  height: 160px;
  padding: 0px;
  object-fit: cover;
`;

const ProfileSymbol = styled.img`
  width: 90px;
  height: 90px;
  border: 6px solid;
  border-color: ${(props) => props.theme.Colors.PageBg};
  border-radius: 50%;
  margin-top: -48px;
  object-fit: cover;
  cursor: pointer;
`;

const ProfileTitle = styled.h1`
color: ${(props) => props.theme.Colors.HeaderText};
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  &.graduate box-icon{
    fill: #0095f6; 
    margin-left: 3px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 20px;
  }`;

const Align = styled.section`
  margin: 0 auto;
  max-width: 300px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 1400px;
  }
`;

const EditButton = styled.button`
  cursor: pointer;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-bottom: 20px !important;
  width: 280px;
  border-radius: 10px;
  border: 1.5px solid;
  font-size: 18px;
  font-weight: 700;
  color: #4198ff; 
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  background-color: ${(props) => props.theme.Colors.PageBg};
  border-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 50px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
    font-size: 20px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.8px;
  margin-left: ${(props) => (props.card ? "20px" : null)};
`;

const ColFlexDiv = styled(FlexDiv)`
  flex-direction: column;
  margin: 20px 0;
`;

const IconDiv = styled(FlexDiv)`
  gap: 16px;
  margin: 16px 0;
`;

const Headingtext = styled.h1`
  color: ${(props) => props.theme.Colors.HeaderText};
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-size: 16px;
  margin-bottom: 9.6px;
`;

const Subheading = styled.h2`
  color: ${(props) => props.theme.Colors.LabelText};
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-weight: 500;
  font-size: 14px;
`;

const EmailIcon = styled.a`
  cursor: pointer;
  & box-icon {
    fill: ${(props) => props.theme.Colors.LabelText};
  }
`;

const Linkedinlogo = styled.a`
  & box-icon {
    fill: #0077b5;
  }
`;
const Githublogo = styled.a`
  & box-icon {
    fill: ${(props) => props.theme.Colors.Profileicon};
  }
`;

const ProfileContainer = styled.div`
  background: ${(props) => props.theme.Colors.PageBg};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};

  margin: 0 20px 32px 20px;
  padding: 20px;
`;

const Heading = styled.h1`
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: ${(props) => props.theme.Colors.HeaderText};
  font-size: 16px;
  margin-top: ${(props) =>
    props.primary ? "32px" : props.skills ? "30px" : "0"};
  margin-bottom: 16px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: ${(props) => (props.primary ? "18px" : "26px")};
    margin-top: 22px;
  }
`;

const AboutText = styled.p`
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.Colors.BodyText};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Skilltags = styled(AboutText)`
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  font-size: 14px;
  width: auto;
  margin: 0 16px 8px 0;
  line-height: 0;
  padding: 16px 16px;
  color: ${(props) => props.theme.Colors.HeaderText} ;
`;

const SkillDiv = styled(FlexDiv)`
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PseudoProfile = styled.div`
  display: inline-block;
  font-size: 26.4px;
  line-height: 48px;
  text-align: center;
  color: white;
  width: 90px;
  height: 90px;
  border: 6px solid;
  border-color: ${(props) => props.theme.Colors.PageBg};
  margin-top: -48px;
  padding-top: 15px;
  border-radius: 50%;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  background: ${(props) => props.theme.Colors.PseudoProfile};
  font-weight: 600;
`;

const AlumniProfile = () => {
  const { slug } = useParams();
  const user = useSelector((state) => state.user);
  const [model, setModel] = useState(false);
  const [isImageValid, setIsImageValid] = useState(true);
  const [response, setResponse] = useState({
    body: "",
    domains: [],
    qualification: "",
    companyName: "",
    designation: "",
    createdBy: {
      profile: "",
      name: "",
      email: "",
    },
    socialProfile: {
      github: "",
      linkedin: "",
    },
    branch: "",
    passOutYear: "",
    college: "",
    postGradYear: "",
    course: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProfile(slug)
      .then((result) => {
        document.title = `Profile | ${result.data.data.createdBy.name}`;
        setResponse(result.data.data);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(false);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
        setLoading(false);
      });
  }, [slug]);

  console.log(response.createdBy.profile, "PROF");
  return (
    <>
      {model && (
        <ImagePopup setModel={setModel} Image={response.createdBy.profile || ""} />
      )}

      {loading && <Loader />}
      <MainWrapper>
        <LeftSection>
          <ProfileContainer>
            <>
              <Heading>About {response.createdBy.name} </Heading>
              {response.body &&
              DOMPurify.sanitize(response.body) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.body),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No description added yet</AboutText>
              )}
            </>
            <>
              <Heading domains>Domains</Heading>
              {response.domains[0] ? (
                <SkillDiv>
                  {response.domains.map((domain, key) => {
                    return <Skilltags key={key}>{domain}</Skilltags>;
                  })}
                </SkillDiv>
              ) : (
                <AboutText>No domains added yet</AboutText>
              )}
            </>
          </ProfileContainer>
          <ProfileContainer>
            <>
              <Heading>Qualification</Heading>
              {response.qualification &&
              DOMPurify.sanitize(response.qualification) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.qualification),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No qualification added yet</AboutText>
              )}
            </>
            <>
              <Heading>Company Name</Heading>
              {response.companyName &&
              DOMPurify.sanitize(response.companyName) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.companyName),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No companyName added yet</AboutText>
              )}
            </>
            <>
              <Heading>Designation</Heading>
              {response.designation &&
              DOMPurify.sanitize(response.designation) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.designation),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No designation added yet</AboutText>
              )}
            </>
          </ProfileContainer>
          <ProfileContainer>
            <>
              <Heading>College (Post Graduation)</Heading>
              {response.college &&
              DOMPurify.sanitize(response.college) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.college),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No college added yet</AboutText>
              )}
            </>
            <>
              <Heading>Course</Heading>
              {response.course &&
              DOMPurify.sanitize(response.course) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.course),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No course added yet</AboutText>
              )}
            </>
            <>
              <Heading>Post Graduation Year</Heading>
              {response.postGradYear &&
              DOMPurify.sanitize(response.postGradYear) !== "<p><br></p>" ? (
                <AboutText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(response.postGradYear),
                  }}
                ></AboutText>
              ) : (
                <AboutText>No passout year added yet</AboutText>
              )}
            </>
          </ProfileContainer>
        </LeftSection>
        <RightSection>
          <MainCardwrapper>
            <ProfileCoverImg src={cover} />
            {response.createdBy.profile && isImageValid ? (
              <ProfileSymbol
                src={response.createdBy.profile}
                onError={() => {
                  setIsImageValid(false);
                }}
                onClick={() => {
                  setModel(true);
                }}
              />
            ) : (
              <PseudoProfile
                dangerouslySetInnerHTML={{
                  __html: response.createdBy.name.split("")[0],
                }}
              />
            )}
            <ProfileTitle className="graduate">
              {response.createdBy.name}
              <box-icon type="solid" name="graduation"></box-icon>
            </ProfileTitle>
            <FlexDiv>
              <Subheading>{response.createdBy.email}</Subheading>
            </FlexDiv>
            {response.socialProfile &&
            (response.socialProfile.linkedin ||
              response.socialProfile.github) ? (
              <IconDiv>
                {response.socialProfile.linkedin && (
                  <Linkedinlogo
                    href={response.socialProfile.linkedin}
                    target="_blank"
                    rel="noopener     noreferrer"
                  >
                    <box-icon name="linkedin-square" type="logo"></box-icon>
                  </Linkedinlogo>
                )}
                {response.socialProfile.github && (
                  <Githublogo
                    href={response.socialProfile.github}
                    target="_blank"
                    rel="noopener    noreferrer"
                  >
                    <box-icon name="github" type="logo"></box-icon>
                  </Githublogo>
                )}
              </IconDiv>
            ) : response.createdBy.email !== user.email ? (
              <IconDiv>
                <EmailIcon href={`mailto:${response.createdBy.email}`}>
                  <box-Icon name="envelope" type="logo"></box-Icon>
                </EmailIcon>
              </IconDiv>
            ) : null}
            <FlexDiv>
              <ColFlexDiv>
                <Headingtext>{response.passOutYear}</Headingtext>
                <Subheading>Graduation Year</Subheading>
              </ColFlexDiv>
            </FlexDiv>
          </MainCardwrapper>
          {slug === user.slug && (
            <Align>
              <Link to={ROUTES.EDITALUMNIPROFILE}>
                <EditButton>Edit Profile</EditButton>
              </Link>
            </Align>
          )}
        </RightSection>
      </MainWrapper>
    </>
  );
};

export default AlumniProfile;