import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getLeaderboard } from "../Services/tml";
import Loader from "../Components/CommonComponents/Loader";
import { toast } from "react-toastify";
import LakshyaBanner from "../Images/LakshyaBanner.png";
import Crown from "../Images/Crown.png";

const MainWrapper = styled.div`
  background: #000000 !important;
  margin-top: 64px;
  padding: 40px 0;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding: 40px 20px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    margin-left: 52px;
  }
`;

const Wrapper = styled.div`
  background: #1d1d1d;
  border-radius: 10px;
  /* height: 50vh; */
  padding-bottom: 30px;
  width: 90vw;
  max-width: 300px;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    height: 60vh;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 700px;
    height: 85vh;
    width: ${(props) => (props.left ? "40vw" : "60vw")};
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 1400px;
    height: 80vh;
    width: ${(props) => (props.left ? "35vw" : "65vw")};
  }
`;

const Circle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  border: double 3px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-style: normal;
  font-weight: 600;
  color: white;
  &.orange {
    width: 120px;
    height: 120px;
    background-image: linear-gradient(#1d1d1d, #1d1d1d),
      radial-gradient(circle at top, #ffc979, #ff4d00);
    font-size: 13px;
  }
  &.blue {
    background-image: linear-gradient(#1d1d1d, #1d1d1d),
      radial-gradient(circle at top, #66ffe3, #09307c);
    font-size: 13px;
  }
  &.red {
    background-image: linear-gradient(#1d1d1d, #1d1d1d),
      radial-gradient(circle at top, #d73636, #763900);
    font-size: 13px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 100px;
    height: 100px;
    &.orange {
      width: 150px;
      height: 150px;
      font-size: 22px;
    }
    &.blue {
      font-size: 18px;
    }
    &.red {
      font-size: 18px;
    }
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  grid-gap: 15px;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    flex-direction: row;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    margin-top: 30px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin-top: 90px;
  }
`;

const Banner = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 130px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: auto;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: white;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-style: normal;
  font-weight: 600;
  z-index: 1;
  margin-top: -90px;
  font-size: 20px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 30px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 40px;
  }
`;

const Points = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: white;
  z-index: 1;
  padding: 5px;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  border: solid 4px #1d1d1d;
  background-origin: border-box;
  background-clip: content-box, border-box;
  &.orange {
    background: linear-gradient(transparent, transparent),
      radial-gradient(circle at top, #ffc979, #ff4d00);
    top: 75px;
    left: 35px;
  }
  &.blue {
    background: linear-gradient(transparent, transparent),
      radial-gradient(circle at top, #66ffe3, #09307c);
    top: 40px;
    left: 15px;
  }
  &.red {
    background: linear-gradient(transparent, transparent),
      radial-gradient(circle at top, #d73636, #763900);
    top: 40px;
    left: 15px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    &.orange {
      top: 110px;
      left: 50px;
    }
    &.blue {
      top: 60px;
      left: 25px;
    }
    &.red {
      left: 25px;
      top: 60px;
    }
  }
`;

const CrownImg = styled.img`
  position: absolute;
  z-index: 1;
  width: 40px;
  top: -30px;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 80px;
    top: -55px;
  }
`;

const LabelText = styled.p`
  padding-top: 5px;
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  color: rgba(255, 255, 255, 0.7);
`;

const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Progress = styled.div`
  max-width: 260px;
  margin: 0 auto;
  padding-top: 10px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    padding: 30px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 400px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 800px;
    padding: 20px 10px 0 10px;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  height: 23px;
  margin-top: 10px;
  background: linear-gradient(
    270deg,
    #e9194b 2.77%,
    rgba(170, 0, 250, 0.44) 100%
  );
  border-radius: 5px;
  font-size: 16px;
  padding-left: 10px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 30px;
    margin-top: 20px;
    font-size: 18px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 35px;
  }
`;
const ProgressBarDiv = styled.div`
  height: 25px;
  width: ${Progress};
  margin-top: 10px;
  background-color: #2a2a2a;
  border-radius: 5px;
  font-size: 16px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 30px;
    margin-top: 20px;
    font-size: 18px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 35px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  padding-top: 10px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding-top: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  width: 200px;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
  }
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-style: normal;
  font-size: 14px;
  color: white;
  padding-top: 2px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 18px;
    padding-top: 5px;
  }
`;

const TextPoint = styled.p`
  display: none;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    display: block;
    float: right;
    margin-bottom: auto;
    position: relative;
    right: 20px;
    font-family: ${(props) => props.theme.Fonts.Poppins};
    font-style: normal;
    font-size: 14px;
    color: white;
    top: -50px;
    @media ${(props) => props.theme.MediaQueries.m.query} {
      font-size: 18px;
    }
  }
  // @media ${(props) => props.theme.MediaQueries.m.query}{
  //   left: 550px;
  // }
  // @media ${(props) => props.theme.MediaQueries.s.query}{
  //   left: 450px;
  // }
`;

const TmlLeaderBoard = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([
    {
      branch: "",
      points: "",
    },
  ]);
  const [maxPoints, setMaxPoints] = useState(0);

  useEffect(() => {
    document.title = "TML | Lakshya Scoreboard";
    setLoading(true);
    getLeaderboard()
      .then((result) => {
        setResponse(result.data.data);
        setLoading(false);
        setMaxPoints(result.data.data[0].points);
      })
      .catch((err) => {
        setResponse(null);
        setLoading(false);
        toast.warn(err.response.data.message);
      });
  }, []);

  return (
    <>
      {" "}
      {loading && <Loader />}
      {response && maxPoints && response.length > 1 && (
        <MainWrapper>
          <FlexDiv>
            <Wrapper left>
              <Container>
                <CircleContainer>
                  <Circle className="blue">
                    {response[1].branch}
                    <Points className="blue">2</Points>
                  </Circle>

                  <LabelText>{response[1].points} pts</LabelText>
                </CircleContainer>

                <CircleContainer>
                  <Circle className="orange">
                    <CrownImg src={Crown}></CrownImg>
                    {response[0].branch}
                    <Points className="orange">1</Points>
                  </Circle>

                  <LabelText>{response[0].points} pts</LabelText>
                </CircleContainer>

                <CircleContainer>
                  <Circle className="red">
                    {response[2].branch}
                    <Points className="red">3</Points>
                  </Circle>
                  <LabelText>{response[2].points} pts</LabelText>
                </CircleContainer>
              </Container>

              <Table>
                {response.slice(3, 6).map((data, index) => {
                  return (
                    <>
                      {/* <Row>
                        <Text>{index + 4}</Text>
                        <Text>{data.branch}</Text>
                        <Text>{data.points} pts</Text>
                      </Row> */}
                    </>
                  );
                })}
              </Table>
            </Wrapper>
            <Wrapper>
              <Banner src={LakshyaBanner} />
              <Heading>Lakshya Leaderboard</Heading>
              <Progress>
                {response.map((data, index) => {
                  return (
                    <>
                      <ProgressBarDiv>
                        <ProgressBar
                          style={{
                            width: `${(data.points / maxPoints) * 100}%`,
                          }}
                          key={index}
                        >
                          <Text>{data.branch}</Text>
                        </ProgressBar>
                        <TextPoint>{data.points} pts</TextPoint>
                      </ProgressBarDiv>
                    </>
                  );
                })}
              </Progress>
            </Wrapper>
          </FlexDiv>
        </MainWrapper>
      )}
    </>
  );
};

export default TmlLeaderBoard;
