import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAllQuery } from "../Services/alumni";
import Loader from "../Components/CommonComponents/Loader";
import QueryCard from "../Components/QueryCard";
import { toast } from "react-toastify";
import empty from "../Images/CommonComponents/Empty.svg";
import Jumbotron from "../Components/CommonComponents/Jumbotron";

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0 auto;
  padding-top: 0;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 768px;
    padding-left: 0;
    flex-direction: row;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 0;
    max-width: 1200px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 1rem;
  margin-top: 6px;
  // width: 100%;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 42vw;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    top: 120px;
    max-width: 28vw;
    align-items: flex-end;
    margin-right: 20px;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 65vw;
  }
`;

const RightCard = styled.div`
  @media ${(props) => props.theme.MediaQueries.m.query} {
    position: sticky;
    top: 90px;
    margin-bottom: 60px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  // width: 340px;
  margin: 0.5rem;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    // width: 576px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    // width: 768px;
    width: 58vw;
    // padding-top: 64px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 58vw;
    // margin-left: 1.5rem;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 65vw;
  }
`;

const SideCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 15px;
  margin-bottom: 22px;
  padding: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
  /* padding: 32px; */
  width: 90vw;
  max-width: 350px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 36vw;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 29vw;
  }

  @media ${(props) => props.theme.MediaQueries.xl.query} {
    max-width: 25vw;
  }
`;

const SideHead = styled.p`
  font-size: 16.8px;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-weight: 700;
  color: ${(props) => props.theme.Colors.GithubBg};
  max-width: 220px;
  margin: 7px 0px 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    max-width: 272px;
  }
`;

const SideBody = styled.p`
  font-family: ${(props) => props.theme.Fonts.Poppins};
  color: ${(props) => props.theme.Colors.GithubBg};
  font-size: 14px;
  width: auto;
  max-width: 720px;
  // overflow-y:hidden;
  height: auto;
  white-space: no-wrap;
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    max-width: 60vw;
  }
`;

const SideButton = styled.button`
  // padding: 10px;
  color: ${(props) => props.theme.Colors.White};
  background-color: ${(props) => props.theme.Colors.YellowBtn};
  width: 114px;
  // text-align: center;
  // margin:auto;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.Colors.YellowBtn};
  cursor: pointer;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: ${(props) => props.theme.Colors.YellowBtnHover};
  }
`;

const ReadMore = styled.button`
  padding: 10px 20px;
  font-family: ${(props) => props.theme.Fonts.Poppins};
  background-color: ${(props) => props.theme.Colors.LoadBg};
  font-size: 16px;
  align-self: center;
  margin-top: 10px;
  font-weight: 500;
  width: 154px;
  margin-left: 10px;
  border-radius: 15px;
  border: none;
  color: ${(props) => props.theme.Colors.Load};
  &:hover {
    cursor: pointer;
  }
`;

const Div = styled.main`
  max-width: unset;
  padding-right: 0;
  padding-bottom: 20px !important;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 53px;
  }
`;

const Empty = styled.div`
  margin: 0 auto;
`;

const EmptyImg = styled.div`
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
  }
`;

const EmptyMsg = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  color: ${(props) => props.theme.Colors.LabelText};
`;

const AlumniDashboard = () => {
  const [value, setValue] = useState(false);
  let navigate = useNavigate();
  const name = "";
  const domain = "All";
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const page = 1;
  let limit = 3;
  let currentDate = new Date();
  let displayTime;
  const valueCrossed = (name, value) => {
    if (value === name) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    document.title = "Alumni Dashboard";
    setValue(false);
    setLoading(true);
    getAllQuery(name, domain, page, limit)
      .then((result) => {
        setResponse(result.data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setResponse(null);
        setLoading(false);
        toast.warn(err.response.data.message);
      });
  }, [name, domain, page, limit, value]);

  return (
    <>
      {loading && <Loader />}
      <Div>
        <Jumbotron disabled label="Alumni Dashboard" Alumni />
        <MainWrapper>
          {response ? (
            <LeftSection>
              {response.slice(0, 3).map((data, index) => {
                let year = new Date(data.createdAt).getFullYear();
                let month = parseInt(data.createdAt.slice(5, 7));
                let date = new Date(data.createdAt).getDate();
                let hours = new Date(data.createdAt).getHours();
                let minutes = new Date(data.createdAt).getMinutes();

                valueCrossed(month, currentDate.getMonth() + 1)
                  ? (displayTime =
                      new Date(data.createdAt).toDateString().slice(0, 3) +
                      ", " +
                      new Date(data.createdAt).toDateString().slice(4, 7) +
                      " " +
                      date +
                      " " +
                      year)
                  : valueCrossed(date, currentDate.getDate())
                  ? (displayTime = currentDate.getDate() - date + "d ago")
                  : valueCrossed(hours, currentDate.getHours())
                  ? (displayTime = currentDate.getHours() - hours + "h ago")
                  : valueCrossed(minutes, currentDate.getMinutes())
                  ? (displayTime = currentDate.getMinutes() - minutes + "m ago")
                  : (displayTime = "just now");

                return (
                  <QueryCard
                    key={index}
                    name={data.createdBy.name}
                    description={data.body}
                    slug={data.slug}
                    title={data.query}
                    time={displayTime}
                    domain={data.domain}
                    userData={data.createdBy}
                    setValue={setValue}
                    disabled
                    status={data.openQueryStatus}
                  />
                );
              })}
              <ReadMore
                onClick={() =>
                  navigate(`../gstforum`, {
                    replace: false,
                  })
                }
              >
                View All
              </ReadMore>
            </LeftSection>
          ) : (
            <Empty empty>
              <EmptyImg>
                <img src={empty} alt="Empty"></img>
              </EmptyImg>
              <EmptyMsg>No Queries found</EmptyMsg>
            </Empty>
          )}
          <RightSection>
            <RightCard>
              <SideCard>
                <SideHead>Write a Blog!</SideHead>
                <SideBody>
                  Make your presence known among the GSTians by offering them
                  valuable insight through our new Blog feature on the Portal.{" "}
                </SideBody>
                <SideButton
                  onClick={() =>
                    navigate(`../blogs/create`, {
                      replace: false,
                    })
                  }
                >
                  Start Writing
                </SideButton>
              </SideCard>
              <SideCard>
                <SideHead>Got any Internships?</SideHead>
                <SideBody>
                  The new Internship feature allows you to recruit our talented
                  engineers for any ambitious project.{" "}
                </SideBody>
                <SideButton
                  onClick={() =>
                    navigate(`../alumni/announcement/internship`, {
                      replace: false,
                    })
                  }
                >
                  Next
                </SideButton>
              </SideCard>
            </RightCard>
          </RightSection>
        </MainWrapper>
      </Div>
    </>
  );
};

export default AlumniDashboard;
