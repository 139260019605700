import axiosJWT from "./axiosJWT";

export const getNotifications = (page, limit) => {
  return axiosJWT({
    method: "GET",
    url: `/api/v1/notification/getAllNotifications?page=${page}&limit=${limit}`,
  });
};

export const updateStatus = (notificationData) => {
  return axiosJWT({
    method: "PUT",
    url: `/api/v1/notification/updateStatus`,
    data: {_id : notificationData._id},
  });
};


