import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getNotifications } from "../../Services/notification";
import Pagination from "./Pagination";
import {  useSelector } from "react-redux";
import { updateStatus } from "../../Services/notification";
import { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../Constants/routes";
import jwt_decode from "jwt-decode";
import Loader from "./Loader";
import empty from "../../Images/CommonComponents/Empty.svg";

const NotificationDiv = styled.div`
  width: 375px;
  // height: 358px;
  border-radius: 8px;
  display: none;
  position: absolute;
  top: 50px;
  right: 70px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  box-shadow: ${(props) => props.theme.Colors.Shadow};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    right: 8px;
    width: 95vw;
  }
`;

const NotificationHeader = styled.h1`
  height: 20px;
  font-size: 16px;
  font-weight: 700;
  margin: 10px;
  color: ${(props) => props.theme.Colors.HeaderText};
  font-family: ${(props) => props.theme.Fonts.Inter};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 14px;
  }
`;

const NotificationDivList = styled.ul`
  display: flex;
  justify-content: flex-center;
  align-items: start;
  padding-bottom : 10px;
`;

const NotificationList = styled.li`
  display: flex;
  font-family: ${(props) => props.theme.Fonts.Inter};
  flex-direction: column;
  justify-content: flex-center;
  align-items: start;
`;

const TeamName = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 12px, 16px, 12px, 16px;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 12px;
  }
`;

const Tag = styled.p`
  border: 1px solid ${(props) => props.theme.Colors.BlogDate};
  border-radius: 23.5px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding: 2px 10px;
  margin: 0.5px 0;
  width: fit-content;
  height: fit-content;
  justify-self: flex-end;
  color: ${(props) => props.theme.Colors.BodyText};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 10.4px;
  }
`;

const NotificationContent = styled.div`
  width: 375px;
  height: fit-content;
  cursor: pointer;
  padding: 12px 5px;
  display: grid;
  grid-template-columns: 50px auto 120px;
  border-bottom: 1.3px solid ${(props) => props.theme.Colors.BlogDate};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    right: 8px;
    width: 95vw;
  }
  ${(props) =>
    props.status === "false"
      ? css`
      border-bottom: 1.3px solid ${(props) =>
        props.theme.Colors.NavbarSideNavBg};
      background-color: ${(props) => props.theme.Colors.unseenNotification};
      ${TeamName} {
        color: ${(props) => props.theme.Colors.HeaderText};
      }
      ${Tag} {
        border: 1px solid ${(props) => props.theme.Colors.NavbarSideNavBg};
      }
    }
    `
      : css`
          background-color: none;
          ${TeamName} {
            color: ${(props) => props.theme.Colors.HeaderText};
          }
        `}
`;

const TeamImg = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  margin: 5px 10px;
  margin-left: ${(props) => (props.nav ? "11.2px" : null)};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 28px;
    height: 28px;
  }
`;

const ProfileImg = styled.div`
  display: inline-block;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  background: ${(props) => props.theme.Colors.PseudoProfile};
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: 5px 10px;
  margin-left: ${(props) => (props.nav ? "11.2px" : null)};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 28px;
    height: 28px;
    font-size: 16px;
  }
`;

const TeamDescription = styled.div`
  max-width: 90%;
  margin: 0 5px;
`;

const Title = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.Colors.BodyText};
  margin: 2.5px 0;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 10px;
  }
`;

const TagDiv = styled.div`
  margin: 0 5px;
  display: grid;
  justify-content: flex-end;
`;

const Time = styled.p`
  font-size: 10px;
  color: ${(props) => props.theme.Colors.BodyText};
  text-align: end;
  margin: 2px 12px;
`;
const Empty = styled.div`
  margin: 0 auto;
`;
const EmptyImg = styled.div`
  width: 120px;
  margin: 50px auto 0 ;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 150px;
  }
`;
const EmptyMsg = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  color: ${(props) => props.theme.Colors.LabelText};
`;

const Page = styled.div``;

const NotificationFeature = () => {
  const navigate = useNavigate();
  let currentDate = new Date();
  let displayTime;
  const valueCrossed = (name, value) => {
    if (value === name) {
      return false;
    } else {
      return true;
    }
  };

  let [notification, setNotification] = useState([]);
  const user = useSelector((state) => state.user);
  const userId = user.isLoggedIn ? jwt_decode(user.accessToken).userId : null;
  let [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 4;

  const pageChange = (pageNo) => {
    setPage(pageNo);
  };

  useEffect(() => {
    setLoading(true)
    getNotifications(page, limit)
      .then((result) => {
        setNotification(result.data.data.result);
        setTotalPages(result.data.data.totalPage);
        setLoading(false)
      })
      .catch((error) => {
        setNotification(null);
        setTotalPages(0);
      });
  }, [page, limit]);

  const markAsSeen = (notificationData) => {
    const checkStatus = notificationData.seenId.includes(userId);
    if (!checkStatus) {
      updateStatus(notificationData)
      .then((result) => {
      })
      .catch((error) => {
        // console.log("err", error);
      });
    }
    navigation(notificationData);
  };

  const navigation = (notificationData) => {
    const { type } = notificationData;
    let contentUrl = "";
    if (type === "Blog") {
      contentUrl = ROUTES.BLOGS_ALL;
    } else if (type === "Announcement") {
      contentUrl = ROUTES.ANNOUNCEMENTS;
    } else if (type === "Event") {
      contentUrl = ROUTES.EVENTS;
    } else if (type === "Query") {
      contentUrl = ROUTES.ALLQUERIES;
    }
    if (window.location.pathname == contentUrl) {
      window.location.href = contentUrl;
    }
    else{
      navigate(contentUrl);
    }
  };

  let notifications;
  notifications = (
    <NotificationDiv>
      <NotificationHeader>Notifications</NotificationHeader>
      <NotificationDivList>
        {notification? (
          <NotificationList>
          {notification.map((notificationData, index) => {
            let year = new Date(notificationData.createdAt).getFullYear();
              let month = parseInt(notificationData.createdAt.slice(5, 7));
              let date = new Date(notificationData.createdAt).getDate();
              let hours = new Date(notificationData.createdAt).getHours();
              let minutes = new Date(notificationData.createdAt).getMinutes();

              valueCrossed(month, currentDate.getMonth() + 1)
                ? (displayTime =
                    new Date(notificationData.createdAt).toDateString().slice(4, 7) +
                    " " +
                    date +
                    " " +
                    year)
                : valueCrossed(date, currentDate.getDate())
                ? (displayTime = currentDate.getDate() - date + "d ago")
                : valueCrossed(hours, currentDate.getHours())
                ? (displayTime = currentDate.getHours() - hours + "h ago")
                : valueCrossed(minutes, currentDate.getMinutes())
                ? (displayTime = currentDate.getMinutes() - minutes + "m ago")
                : (displayTime = "just now");

            const seenUser = notificationData.seenId.includes(userId);

            return (
              <NotificationContent
                status={seenUser ? "true" : "false"}
                key={notificationData._id}
                onClick={() => markAsSeen(notificationData)}
              >
                {notificationData.createdBy.profile ? (
                  <TeamImg src={notificationData.createdBy.profile} />
                ) : (
                  <>
                    <ProfileImg
                      dangerouslySetInnerHTML={{
                        __html: notificationData.createdBy.name.split("")[0],
                      }}
                    />
                  </>
                )}
                <TeamDescription>
                  <TeamName>{notificationData.createdBy.name}</TeamName>
                  <Title>{notificationData.title}</Title>
                </TeamDescription>
                <TagDiv>
                  <Tag
                    dangerouslySetInnerHTML={{
                      __html:
                        notificationData.type.charAt(0).toUpperCase() +
                        notificationData.type.slice(1),
                    }}
                  ></Tag>
                  <Time>{displayTime}</Time>
                </TagDiv>
              </NotificationContent>
            );
          })}
        </NotificationList>
        ):(
          <Empty empty>
          <EmptyImg>
            <img src={empty} alt="Empty"></img>
          </EmptyImg>
          <EmptyMsg>No notifications found</EmptyMsg>
        </Empty>
        )}
        
      </NotificationDivList>
      <Page>
        <Pagination
          isNotification={true}
          page={page}
          totalPages={totalPages}
          pageChange={pageChange}
        ></Pagination>
      </Page>
    </NotificationDiv>
  );
  return <>{loading && <Loader />}{notifications}</>;
};

export default NotificationFeature;