import React from "react";
import { useState } from "react";
import styled from "styled-components";

const Form = styled.form`
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 16px 0;
  &.disabled {
    display: none;
  }
`;

const SearchBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  max-width: 320px;
  height: 48px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  padding-left: 20.8px;
  padding-right: 90px;
  width: 256px;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    width: 256px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 288px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 320px;
  }
  &::placeholder{
    color: #ffffff;
  }

  &:focus{
    outline: none;
  }
`;

const Button = styled.button`
  color: ${(props) => props.theme.Colors.White};
  border-radius: 40px;
  padding: 0.3;
  border: none;
  max-width: 160px;
  width: 80px;
  height: 35.2px;
  position: absolute;
  top: 14%;
  right: 2%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background-color: #ff9f0f;
  & box-icon {
    fill: ${(props) => props.theme.Colors.White};
  }
`;

function SearchBar({
  placeholder,
  disabled,
  setSearchData,
  reports,
  toggle,
  data,
  setToggle,
}) {
  const [searchField, setSearchField] = useState("");

  return (
    <>
      <Form className={`${disabled ? "disabled" : null}`}>
        <SearchBox>
          <Input
            placeholder={placeholder}
            name="searchField"
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
          <Button
            type="submit"
            onClick={(e) => {
              if (reports) {
                e.preventDefault();
                setSearchData({ ...data, name: searchField });
                setToggle(() => !toggle);
              } else {
                e.preventDefault();
                setSearchData(searchField);
                setToggle(() => !toggle);
              }
            }}
          >
            Search
            {/* <box-icon name="search-alt-2"></box-icon> */}
          </Button>
        </SearchBox>
      </Form>
    </>
  );
}

export default SearchBar;
