import axiosJWT from "./axiosJWT";
const axios = require("axios").default;

export const getAllParticipants = (slug, isApprove, email, page, limit) => {
  return axiosJWT({
    method: "GET",
    url: `/api/v1/tml/participants/${slug}?isApprove=${isApprove}&email=${email}&page=${page}&limit=${limit}`,
  });
};

export const getEventHead = (status, email, page, limit) => {
  return axiosJWT({
    method: "GET",
    url: `/api/v1/tml/eventHead?status=${status}&email=${email}&page=${page}&limit=${limit}`,
  });
};

export const registrationApprove = (id, approve) => {
  return axiosJWT({
    method: "PUT",
    url: `/api/v1/tml/approve/${id}`,
    data: { approve },
  });
};

export const getTmlEvents = async (page, limit, team, name) => {
  return await axios({
    method: "GET",
    url: `/api/v1/tml/?page=${page}&limit=${limit}&team=${team}&name=${name}`,
  });
};

export const registerNonGstain = async (list) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/tml/register/nonGstain`,
    data: {
      name: list.name,
      college: list.college,
      email: list.email,
      contactNumber: list.contactNumber,
      slug: list.slug,
    },
  });
};

export const participatedIn = async (page, limit, team, name) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/tml/participatedIn/?page=${page}&limit=${limit}&team=${team}&name=${name}`,
  });
};

export const updatePayment = async (id, amount) => {
  return axiosJWT({
    method: "PUT",
    url: `/api/v1/tml/payment/${id}`,
    data: amount,
  });
};

export const updatePlayerStatus = async (id, playedStatus) => {
  return axiosJWT({
    method: "PUT",
    url: `/api/v1/tml/playerStatus/${id}`,
    data: { playedStatus },
  });
};

export const gstianRegistration = async (slug) => {
  return axiosJWT({
    method: "POST",
    url: `/api/v1/tml/register/gstain/${slug}`,
  });
};

export const gstianUnRegistration = async (id) => {
  return axiosJWT({
    method: "DELETE",
    url: `/api/v1/tml/unregister/gstain/${id}`,
  });
};

export const checkRegistration = async (slug) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/tml/checkRegistration/${slug}`,
  });
};

export const getLeaderboard = async () => {
  return await axios({
    method: "GET",
    url: `/api/v1/tml/lakshya/scoreboard`,
  });
};

export const uploadExcel = async (form) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/tml/lakshya/scoreboard/dashboard`,
    data: form,
  });
};

export const groupRegistration = async (members, slug) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/tml/groupreg/?slug=${slug}`,
    data: {members : {members}},
  });
};

export const nonGstianGroupRegistration = async (members, slug) => {
  return await axiosJWT({
    method: "POST",
    url: `/api/v1/tml/nongstian/groupreg/?slug=${slug}`,
    data: {members : {members}},
  });
};

export const downloadEventHeadData = async (members , slug ) => {
  return await axiosJWT({
    method: "GET",
    url: `/api/v1/tml/download/?slug= ${slug}`,
    data: {members : {members}},
  });
}