import styled from "styled-components";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../Constants/routes";
import { SIDE_NAV } from "../../Constants/sideNav";
import { useSelector } from "react-redux";

const NavContainer = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 48px;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavList = styled.div`
  display: grid;
  row-gap: 40px;
`;

const NavItems = styled.div`
  margin-top: 3.2px;
  row-gap: 24px;
  display: grid;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    row-gap: 27.2px;
  }
`;

const NavLinkComponent = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14.4px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  color: ${(props) => props.theme.Colors.LabelText};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  & box-icon {
    height: 19px;
    width: 19px;
    margin-right: 8px;
    transition: width 0.3s;
    fill: ${(props) => props.theme.Colors.LabelText} !important;
  }
  &:hover box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText} !important;
  }
  &.active {
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  &.active box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText} !important;
  }
`;

const NavName = styled.span`
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    opacity: 0;
    transition: width 0.3s;
  }
`;

const Nav = styled.div`
  width: 200px;
  position: fixed;
  top: 58px;
  left: -100%;
  height: 100vh;
  padding: 16px 16px 0;
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: 100;
  transition: left 0.5s;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    left: 0;
    top: 64px;
    transition: width 0.4s;
    padding: 19.2px 16px;
    width: 52px;
    &:hover {
      width: 200px;
    }
  }

  &:hover ${NavName} {
    opacity: 1;
  }
`;

const Sidenav = ({ isNavOpen , setNavOpen}) => {
  const { role , eventHead } = useSelector((state) => state.user);
  const NavStyle = isNavOpen ? { left: 0 } : null;

  return (
    <>
      <Nav style={NavStyle}>
        <NavContainer>
          <NavList>
            <NavItems>
              {SIDE_NAV.map((item, index) => {
                if (((item.role && item.role.includes(role)) || !item.role) || (item.name === "Event Dashboard" && eventHead)) {
                  return (
                    <NavLinkComponent
                      key={index}
                      activeClassName="active"
                      onClick={() => {setNavOpen(false)}}
                      to={item.to}
                    >
                      <box-icon type="solid" name={item.icon}></box-icon>
                      <NavName>{item.name}</NavName>
                    </NavLinkComponent>
                  );
                }
              })}
            </NavItems>
          </NavList>
        </NavContainer>
      </Nav>
    </>
  );
};

export default Sidenav;
