import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import {
  comments,
  deleteComment,
  deleteReply,
  reply,
} from "../Services/alumni";
import { getAQuery, upVoteComment } from "../Services/alumni";
import ImagePopup from "../Components/CommonComponents/ImagePopup";
import Loader from "../Components/CommonComponents/Loader";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import empty from "../Images/CommonComponents/Empty.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import * as ROUTES from "../Constants/routes";

const MainWrapper = styled.main`
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 740px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 1400px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.Colors.GithubBg};
  font-family: ${(props) => props.theme.Fonts.Poppins};
  font-size: ${(props) =>
    props.smallfont ? "14px" : props.lightfont ? "15px" : "32px"};
  margin: ${(props) => (props.textcenter ? "0 auto 10px" : "15px auto 0 0")};
  line-height: 32px;
  font-weight: ${(props) => (props.smallfont ? "500" : "700")};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: ${(props) =>
      props.smallfont ? "18px" : props.lightfont ? "15px" : "32px"};
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.space
      ? "space-around"
      : props.between
      ? "space-between"
      : props.center
      ? "center"
      : props.mobend
      ? "flex-end"
      : "Start"};
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  padding: ${(props) => (props.padding ? "10px 20px 20px 20px" : "0")};
  border: ${(props) => (props.border ? "1px solid" : "none")};
  border-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 20px;
  align-self: ${(props) => (props.replypic ? "flex-start" : "none")};
  max-width: 90%;
  margin: ${(props) =>
    props.top
      ? "40px auto 0"
      : props.none
      ? "0"
      : props.side
      ? "0 10px"
      : props.margin40
      ? "40px auto"
      : "0 auto"};
  @media ${(props) => props.theme.MediaQueries.s.query} {
    width: ${(props) => (props.reply ? "7%" : props.logo ? "36px" : "100%")};
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
    width: ${(props) => (props.reply ? "9%" : "14%")};
  }
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    flex-direction: ${(props) => (props.mobcol ? "column" : "row")};
    /* width: 100%; */
    width: ${(props) =>
      props.reply ? "10%" : props.nowidth ? "none" : "100%"};
    /* justify-content: ${(props) =>
      props.mobstart ? "flex-start" : "center"}; */
    justify-content: ${(props) =>
      props.mobstart ? "flex-start" : props.mobend ? "flex-end" : "center"};
    align-items: ${(props) => (props.mobcenter ? "center" : "start")};
    align-self: ${(props) => (props.alignSelf ? "center" : "none")};
    margin: ${(props) =>
      props.mobmargin
        ? "20px"
        : props.none
        ? "0"
        : props.top
        ? "40px auto 0"
        : props.margin5
        ? "5px auto"
        : "0 auto"};
    padding: ${(props) => (props.padding ? "15px" : "0")};
  }
  @media ${(props) => props.theme.MediaQueries.mtl.query} {
    flex-direction: ${(props) => (props.mobcol ? "column" : "row")};
    /* width: 100%; */
    width: ${(props) => (props.w100 ? "100%" : props.nowidth ? "none" : "70%")};
    justify-content: ${(props) =>
      props.mobstart ? "flex-start" : props.mobend ? "flex-end" : "center"};
    align-items: ${(props) => (props.mobcenter ? "center" : "start")};
    align-self: ${(props) => (props.alignSelf ? "center" : "none")};
    margin: ${(props) =>
      props.mobmargin
        ? "20px"
        : props.none
        ? "0"
        : props.top
        ? "40px auto 0"
        : props.side
        ? "0 10px"
        : "0 auto"};
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: ${(props) =>
      props.reply
        ? "7%"
        : props.logo
        ? "36px"
        : props.nowidth
        ? "none"
        : "100%"};
  }
  /* @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: ${(props) => (props.reply ? "9%" : "100%")};
  } */
`;

const FlexDiv1 = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? "space-around" : "center")};
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  max-width: 90%;
  margin: ${(props) => (props.margside ? "0 21px" : "0 auto")};
  margin-top: ${(props) => (props.margintop ? "30px" : "0")};
  margin-bottom: ${(props) => (props.marginbottom ? "30px" : "0")};
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin: ${(props) => (props.margside ? "0 34px" : "0 auto")};
    margin-bottom: ${(props) => (props.marginbottom ? "30px" : "0")};
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
  }
`;
const Commentor = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? "space-around" : "center")};
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  max-width: 90%;
  margin: 0 auto;
  margin-top: ${(props) => (props.margintop ? "50px" : "0")};
`;

const Time = styled.p`
  font-size: 10.6px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 600;
  margin: 2px 0 0 6px;
  color: ${(props) => props.theme.Colors.LabelText};
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin: ${(props) => (props.margside ? "2px 0 0 12px" : "2px 0 0 6px")};
  }
`;
const CommentInfo = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? "space-around" : "center")};
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  max-width: 90%;
  margin-top: ${(props) => (props.margintop ? "50px" : "0")};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
  }
`;

const CommentDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: Space-around;
  align-items: center;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 70%;
    justify-content: Space-evenly;
  }
`;

const CommentProfile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 13px;
  justify-content: space-between;
`;

const DeleteLogo = styled.a`
  & box-icon {
    fill: #ff5d61;
    cursor: pointer;
    width: 18px;
  }
`;
const LikedLogo = styled.a`
  & box-icon {
    cursor: pointer;
  }
`;
const ReplyLogo = styled.a`
  & box-icon {
    cursor: pointer;
    width: 18px;
    fill: "#fff";
  }
`;

const SubTitleDiv = styled.div`
  display: flex;
  justify-content: Space-between;
  align-items: start;
  flex-direction: row;
  max-width: 90%;
  margin: 0 auto;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    flex-direction: column;
    width: 100%;
  }
  @media ${(props) => props.theme.MediaQueries.s.query} {
    flex-direction: row;
    max-width: 395px;
  }
`;

const QueryContent = styled.div`
  display: flex;
  justify-content: ${(props) =>
    (props.space ? "space-around" : "Start") &&
    (props.between ? "space-between" : "Start")};
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  max-width: 100%;
  width: 100%;
  min-width: ${(props) => (props.reply ? "70%" : "80%")};
  margin: ${(props) => (props.top ? "40px auto" : props.none ? "0" : "0 auto")};

  @media ${(props) => props.theme.MediaQueries.m.query} {
    min-width: ${(props) =>
      props.reply ? "70%" : props.comment ? "80%" : "80%"};
  }

  @media ${(props) => props.theme.MediaQueries.l.query} {
    min-width: ${(props) => (props.reply ? "70%" : "100%")};
  }
`;

const Submitbutton = styled.button`
  cursor: pointer;
  max-width: 80px;
  background-color: ${(props) => props.theme.Colors.YellowBtn};
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  box-shadow: 0px 26px 130px rgba(255, 192, 68, 0.13),
    0px 9.49043px 47.4521px rgba(255, 192, 68, 0.0896976),
    0px 4.60743px 23.0371px rgba(255, 192, 68, 0.0723178),
    0px 2.25865px 11.2932px rgba(255, 192, 68, 0.0576822),
    0px 0.893072px 4.46536px rgba(255, 192, 68, 0.0403024);
  border-radius: 9px;
  float: right;
  margin: 15px 0 30px 0;
`;

const QueryDiv = styled.div`
  display: none;
  width: 90%;
  transition: 0.5s;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  margin: ${(props) =>
    props.margin ? "40px 40px 0" : props.none ? "0" : "7px auto"};
  max-width: 100%;
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin: 3px 0 0 10%;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin: 3px 0 0 49px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 91%;
    margin: 3px 0 0 54px;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 94%;
    margin: 3px 0 0 48px;
  }
`;

const BackLogo = styled.a`
  & box-icon {
    fill: #385e73;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const LightText = styled.p`
  color: ${(props) => props.theme.Colors.BodyText};
  font-size: 14.4px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  margin: 10px 0;
  padding: ${(props) => (props.padnone ? "0" : "0 10px")};
  display: ${(props) =>
    props.hide ? "none" : props.flex ? "flex" : "inline-block"};
  align-items: center;
  gap: 9px;
  cursor: ${(props) => (props.pointer ? "pointer" : "initial")};
  border-right: ${(props) => (props.part ? "1px solid" : "none")};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 14.4px;
  }
`;

const SmallText = styled.p`
  color: ${(props) => props.theme.Colors.BodyText}; 
  font-size: 11.6px;
  position: relative;
  font-family: ${(props) => props.theme.Fonts.Inter};
  margin: 0;
  padding: ${(props) => (props.padnone ? "0" : "0 10px")};
  display: ${(props) =>
    props.hide ? "none" : props.flex ? "flex" : "inline-block"};
  align-items: center;
  gap: 5px;
  cursor: ${(props) => (props.pointer ? "pointer" : "initial")};
  border-right: ${(props) => (props.part ? "1px solid" : "none")};
  &::before {
    display: ${(props) => (props.none ? "none" : "block")};
    content: "";
    position: absolute;
    top: 5px;
    left: -2px;
    width: 3px;
    height: 3px;
    background: ${(props) => props.theme.Colors.Dot} ;
    border-radius: 50%;
  }
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin: 10px 0;
  }
`;

const HiddenLightText = styled.p`
  display: none;
  position: relative;
  color: ${(props) => props.theme.Colors.BodyText};
  font-size: ${(props) => (props.reply ? "11.6px" : "14.4px")};
  font-family: ${(props) => props.theme.Fonts.Inter};
  padding: 0 10px;
  cursor: ${(props) => (props.pointer ? "pointer" : "initial")};
  &::before {
    display: ${(props) => (props.none ? "none" : "block")};
    content: "";
    position: absolute;
    top: 5px;
    left: -2px;
    width: 3px;
    height: 3px;
    background: ${(props) => props.theme.Colors.Dot} ;
    border-radius: 50%;
  }
`;

const AuthorText = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  margin: ${(props) => (props.margin6 ? "0 6px" : "0")};
  color: ${(props) => props.theme.Colors.HeaderText};
  font-size: 14.4px;
  min-width: 170px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  cursor: pointer;
  line-height: 21px;
  &.graduate box-icon {
    fill: #0095f6;
    margin-left: 3px;
    width: 20px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    margin: ${(props) => (props.margin6 ? "0 6px" : "0")};
  }
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin: ${(props) => (props.margin6 ? "0 12px" : "0")};
  }
  // @media ${(props) => props.theme.MediaQueries.ts.query} {
  //   margin-left: ${(props) => (props.marginl0 ? "0" : "none")};
  // }
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.Colors.BodyText};
  font-size: 14.4px;
  line-height: 36px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  margin: 56px 0 0 20px;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 12px;
    margin: 56px 0 0;
    line-height: 26px;
  }
  @media ${(props) => props.theme.MediaQueries.s.query} {
    margin: 100px 0 0;
    line-height: 26px;
  }
`;

const SubTitleLink = styled.a`
  color: ${(props) => props.theme.Colors.TextLink};
  font-size: 14.4px;
  line-height: 36px;
  cursor: pointer;
  text-decoration: underline;
  font-family: ${(props) => props.theme.Fonts.Inter};
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    font-size: 12px;
    margin: 1px 0 0;
    line-height: 26px;
  }
  @media ${(props) => props.theme.MediaQueries.s.query} {
    line-height: 26px;
    margin: 100px 0 0;
  }
  @media ${(props) => props.theme.MediaQueries.xxl.query} {
    margin: 100px 0 0;
  }
`;

const Coverimage = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 0 32px 0;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 760px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    max-width: 900px;
  }
`;

const IconDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  border: 0.4px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 4px;
  padding: 10px;
  margin: 0;
  width: 130px;
`;

const Tagdiv = styled.div`
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 90%;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin: 10px 0px;
  }
`;

const Tag = styled.p`
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  border-radius: 23.5px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 600;
  font-size: 14.47px;
  line-height: 21.7px;
  padding: 3px 15px;
  margin: 0 0 13px 0;
  color: ${(props) => props.theme.Colors.HeaderText};
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin-right: 13px;
    margin-bottom: 0;
  }
`;

const ProfilePic = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 54.5px;
  margin-top: -3px;
  
`;

const PseudoProfile = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 3px;
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.comment ? "12px" : "16px")};
  font-weight: 500;
  background: ${(props) => props.theme.Colors.PseudoProfile};
  @media ${(props) => props.theme.MediaQueries.t.query} {
    font-size: ${(props) => (props.comment ? "14px" : "16px")};
    width: 30px;
    height: 30px;
  }
`;

const CommentForm = styled.form`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    flex-direction: column;
    align-items: center;
  }
`;

const ReplyForm = styled.form`
  display: none;
  width: ${(props) => (props.replybox ? "100%" : "90%")};
  margin: 10px 5px;
`;

const ReplyDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    flex-direction: column;
  }
`;

const CommentText = styled.input`
  color: ${(props) => props.theme.Colors.BodyText};
  font-size: 12.8px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  background: none;
  border: 0.3px dashed ${(props) => props.theme.Colors.PlaceholderBorder};
  // background-image: linear-gradient(
  //   to right,
  //   #000 40%,
  //   rgba(255, 255, 255, 0) 20%
  // );
  // background-position: top;
  // background-size: 90px 1px;
  // background-repeat: repeat-x;
  border-radius: 13px;
  padding: 10px 0px 60px 10px;
  margin-right: 10px;
  width: 90%;
  @media ${(props) => props.theme.MediaQueries.ts.query} {
    width: ${(props) => (props.replybox ? "100%" : "80%")};
    padding: 10px 0px 30px 10px;
  }
  @media ${(props) => props.theme.MediaQueries.mtl.query} {
    width: 80%;
  }
`;

const Body = styled.p`
  color: ${(props) => props.theme.Colors.Blackwhite};
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: normal;
  font-size: ${(props) => (props.comment ? "12px" : "16px")};
  line-height: 19px;
  text-align: justify;
  letter-spacing: 0.5px;
  word-break: break-all;
  width: 84%;
  margin: 10px 0 9px 32px;
  padding-bottom: ${(props) => (props.comment ? "0" : "20px")};
  min-width: 80%;
  overflow-wrap: anywhere;
  @media ${(props) => props.theme.MediaQueries.t.query} {
    font-size: ${(props) => (props.comment ? "14px" : "16px")};
    margin: 7px 0 9px 43px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    margin: 10px 44px 10px 44px;
  }
  @media ${(props) => props.theme.MediaQueries.s.query} {
    width: 88%;
    margin: 7px 0 9px 48px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: ${(props) => (props.comment ? "14px" : "18px")};
    margin: 7px 0 9px 47px;
  }
`;

const BodyContent = styled.div`
  margin: 0 auto;
  max-width: 90%;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
  }
`;

const CoverFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 32px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 70%;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
`;

const HeaderText = styled.h2`
  cursor: pointer;
  color: ${(props) => props.theme.Colors.HeaderText};
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  font-size: 14.4px;
  text-transform: uppercase;
`;

const Horizontalline = styled.div`
  &:after {
    content: "";
    display: block;
    height: 0.8px;
    width: 40%;
    margin: 10px auto;
    background-color: ${(props) => props.theme.Colors.PlaceholderBorder};
    @media ${(props) => props.theme.MediaQueries.ts.query} {
      width: 65%;
    }
  }
`;

const EmailIcon = styled.a`
  cursor: pointer;
  & box-icon {
    fill: ${(props) => props.theme.Colors.LabelText};
  }
`;

const Linkedinlogo = styled.a`
  cursor: pointer;
  padding-right: 5px;
  & box-icon {
    fill: #0077b5;
    height: 22.4px;
  }
`;

const Githublogo = styled.a`
  cursor: pointer;
  padding-left: 5px;
  & box-icon {
    fill: ${(props) => props.theme.Colors.Blackwhite};
    height: 22.4px;
  }
`;

const Empty = styled.div`
  min-width: 100%;
  margin-top: 40px;
`;

const EmptyImg = styled.div`
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
  }
`;

const EmptyMsg = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  color: ${(props) => props.theme.Colors.LabelText};
`;

const Verticalline = styled.div`
  &:after {
    content: "";
    display: block;
    height: ${(props) => (props.short ? "15px" : "30px")};
    width: 0.8px;
    margin: 0 5px;
    padding: 2px 0;
    background-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  }
`;

const Container = styled.div`
  display: flex;
  padding-left: 10px;
  max-height: 40px;
  margin-top: 15px;
`;

const OptionListDiv = styled.ul`
  position: relative;
  background-color: none;
  width: 177px;
  z-index: 0;
  visibility: hidden;
  text-align: left;
  margin-left: -180px;
  margin-top: 125px;
  transition: all 0.1s;
  box-shadow: ${(props) => props.theme.Colors.Shadow};
`;

const Options = styled.div`
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;

  & box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText};
    font-size: 12.8px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    top: 210px;
    left: 280px;
  }

  &:hover ${OptionListDiv} {
    visibility: visible;
  }
  &.disabled {
    display: none;
  }
`;

const OptionList = styled.li`
  position: relative;
  color: ${(props) => props.theme.Colors.LabelText};
  background-color: ${(props) => props.theme.Colors.NavbarSideNavBg};
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 18px;
  padding: 12.5px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.Colors.PlaceholderBorder};
  }
  & box-icon {
    height: 16px;
    width: auto;
    margin-right: 12px;
  }
  &:hover {
    background-color: ${(props) =>
      props.main ? "" : props.theme.Colors.PlaceholderBorder};
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  & box-icon {
    height: 18px;
    fill: ${(props) => props.theme.Colors.LabelText};
  }
  &:hover box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText};
  }
  &:active {
    color: ${(props) => props.theme.Colors.HeaderText};
  }
  &:active box-icon {
    fill: ${(props) => props.theme.Colors.HeaderText};
  }
  &.delete box-icon {
    fill: #ff5d61;
  }
`;

const OptionContent = styled.a`
  font-size: 12.8px;
  color: ${(props) => props.theme.Colors.HeaderText};
  /* &:hover {
    background-color: ${(props) =>
    props.main ? "" : props.theme.Colors.PlaceholderBorder};
    color: ${(props) => props.theme.Colors.HeaderText};
  } */
  &.delete {
    color: #ff5d61;
  }
`;

const Domain = styled.p`
  max-width: 100%;
  font-size: 16px;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-weight: 700;
  display: flex;
  align-items: start;
  color: ${(props) => props.theme.Colors.BlogAuthor};
  margin: 5px 16px 0 0;
  @media ${(props) => props.theme.MediaQueries.t.query} {
    margin-right: 16px;
    margin-bottom: 0;
    align-items: center;
  }
`;

const DetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7.2px;
  padding-left: 0;
  margin-left: 34px;
  justify-content: flex-start;
`;

const EachQuery = () => {
  const user = useSelector((state) => state.user);
  const userId = user.isLoggedIn ? jwt_decode(user.accessToken).userId : null;
  const { slug } = useParams();
  let navigate = useNavigate();
  const [value, setValue] = useState(false);
  const [images, setImage] = useState([]);
  const [img, setImg] = useState("");
  const [model, setModel] = useState(false);
  const [pageURL, setPageURL] = useState(0);
  const [filter, setFilter] = useState("recent");
  const [queryData, setqueryData] = useState({
    query: "",
    body: "",
    image: [],
    createdAt: "",
    createdBy: {
      name: "",
      slug: "",
      email: "",
      socialProfile: {
        linkedin: "",
        github: "",
      },
    },
  });
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState([]);
  const [comment, setComment] = useState([]);
  const [data, setData] = useState({
    message: "",
  });
  const [replyData, setReplyData] = useState({
    message: "",
    id: "",
  });
  let createdDate = new Date(queryData.createdAt).toDateString();
  let answers = comment.length;
  let currentDate = new Date();
  let displayTime;
  const valueCrossed = (name, value) => {
    if (value === name) {
      return false;
    } else {
      return true;
    }
  };
  let year = new Date(queryData.createdAt).getFullYear();
  let month = parseInt(queryData.createdAt.slice(5, 7));
  let date = new Date(queryData.createdAt).getDate();
  let hours = new Date(queryData.createdAt).getHours();
  let minutes = new Date(queryData.createdAt).getMinutes();

  valueCrossed(month, currentDate.getMonth() + 1)
    ? (displayTime =
        new Date(queryData.createdAt).toDateString().slice(4, 7) +
        " " +
        date +
        " " +
        year)
    : valueCrossed(date, currentDate.getDate())
    ? (displayTime = currentDate.getDate() - date + "d ago")
    : valueCrossed(hours, currentDate.getHours())
    ? (displayTime = currentDate.getHours() - hours + "h ago")
    : valueCrossed(minutes, currentDate.getMinutes())
    ? (displayTime = currentDate.getMinutes() - minutes + "m ago")
    : (displayTime = "just now");

  const Comment = () => {
    if (!data.message || data.message.trim() === "") {
      toast.warn("Please enter comment");
      return false;
    }
    comments(data, slug)
      .then((result) => {
        setValue(true);
        setData({ message: "" });
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  };

  const Reply = (index) => {
    console.log("inside");
    if (!replyData.message || replyData.message.trim() === "") {
      toast.warn("Please enter reply");
      return false;
    }
    reply(replyData, slug)
      .then((result) => {
        var replyForm = document.getElementsByClassName("replybox-hidden");
        var replyInput = document.getElementsByClassName("reply");
        var replyText = document.getElementsByClassName("replyText");
        var hiddenText = document.getElementsByClassName("closeReply");
        replyInput[index].value = "";
        replyForm[index].style.display = "none";
        replyText[index].style.display = "block";
        hiddenText[index].style.display = "none";
        setValue(true);
        setReplyData({ message: "" });
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  };

  const commentDelete = (id) => {
    deleteComment({ id: id }, slug)
      .then((result) => {
        setValue(true);
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  };

  const replyDelete = (id, id2) => {
    deleteReply({ id: id, id2: id2 }, slug)
      .then((result) => {
        setValue(true);
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  };

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);

  const upvote = (upvote, id) => {
    upVoteComment({ upVote: upvote, id: id }, slug)
      .then((result) => {
        setValue(true);
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
      });
  };

  const replyBox = (index) => {
    var replyForm = document.getElementsByClassName("replybox-hidden");
    var replyText = document.getElementsByClassName("replyText");
    var hiddenText = document.getElementsByClassName("closeReply");
    replyForm[index].style.display = "flex";
    replyText[index].style.display = "none";
    hiddenText[index].style.display = "block";
  };

  const closeReplyBox = (index) => {
    var replyForm = document.getElementsByClassName("replybox-hidden");
    var replyText = document.getElementsByClassName("replyText");
    var hiddenText = document.getElementsByClassName("closeReply");
    replyForm[index].style.display = "none";
    replyText[index].style.display = "block";
    hiddenText[index].style.display = "none";
  };

  const ReplyShow = (index) => {
    var hiddenReply = document.getElementsByClassName("showReply");
    var showReply = document.getElementsByClassName("hideReply");
    var showReplies = document.getElementsByClassName("showReplies");
    showReplies[index].style.display = "none";
    hiddenReply[index].style.display = "flex";
    showReply[index].style.display = "block";
  };

  const ReplyHide = (index) => {
    var hiddenReply = document.getElementsByClassName("showReply");
    var showReply = document.getElementsByClassName("hideReply");
    var showReplies = document.getElementsByClassName("showReplies");
    showReplies[index].style.display = "block";
    hiddenReply[index].style.display = "none";
    showReply[index].style.display = "none";
  };

  useEffect(() => {
    setLoading(true);
    setValue(false);
    getAQuery(slug, filter)
      .then((result) => {
        // console.log(result.data.data.result);
        setComment(result.data.data.comments);
        var hiddenReply = document.getElementsByClassName("showReply");
        var showReply = document.getElementsByClassName("hideReply");
        var showReplies = document.getElementsByClassName("showReplies");
        var replyForm = document.getElementsByClassName("replybox-hidden");
        var replyText = document.getElementsByClassName("replyText");
        var hiddenText = document.getElementsByClassName("closeReply");
        for (let i = 0; i < result.data.data.comments.length; i++) {
          hiddenReply[i].style.display = "none";
          showReply[i].style.display = "none";
          if (result.data.data.comments[i].reply.length > 0) {
            showReplies[i].style.display = "block";
          } else {
            showReplies[i].style.display = "none";
          }
          replyForm[i].style.display = "none";
          replyText[i].style.display = "block";
          hiddenText[i].style.display = "none";
        }
        setqueryData({
          ...data,
          createdBy: {
            ...data.createdBy,
            name: result.data.data.result.createdBy.name,
            slug: result.data.data.result.createdBy.role.slug,
            socialProfile: result.data.data.result.createdBy.role.socialProfile,
            email: result.data.data.result.createdBy.email,
          },
          query: result.data.data.result.query,
          body: result.data.data.result.body,
          createdAt: result.data.data.result.createdAt,
        });
        setDomain(result.data.data.result.domain);
        document.title = `Query | ${result.data.data.result.query}`;
        // window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(false);
        setImage(result.data.data.result.images);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
        setLoading(false);
      });
  }, [value, filter]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <>
      {loading && <Loader />}
      <MainWrapper>
        <FlexDiv top mobcol>
          <BackLogo
            onClick={() => {
              navigate(ROUTES.ALLQUERIES);
            }}
          >
            <box-icon name="arrow-back"></box-icon>
          </BackLogo>
        </FlexDiv>
        <FlexDiv>
          <Title smallfont textcenter>
            {queryData.query}
          </Title>
        </FlexDiv>
        <FlexDiv center>
          <LightText>{answers} Answers </LightText>
        </FlexDiv>
        <Horizontalline />
        <FlexDiv space mobcenter>
          <FlexDiv1 column start>
            <HeaderText
              onClick={() => {
                navigate(
                  `${ROUTES.PROFILE.split(":")[0]}${queryData.createdBy.slug}`
                );
              }}
            >
              {queryData.createdBy.name}
            </HeaderText>
            <LightText padnone>{createdDate}</LightText>
          </FlexDiv1>
          {queryData.createdBy.socialProfile && (queryData.createdBy.socialProfile.linkedin ||
          queryData.createdBy.socialProfile.github) ? (
            <FlexDiv1>
              {queryData.createdBy.socialProfile.linkedin &&
                queryData.createdBy.socialProfile.linkedin !== "" && (
                  <>
                    <Linkedinlogo
                      target="_blank"
                      rel="noopener noreferrer"
                      href={queryData.createdBy.socialProfile.linkedin}
                    >
                      <box-icon name="linkedin-square" type="logo"></box-icon>
                    </Linkedinlogo>
                  </>
                )}
              {queryData.createdBy.socialProfile.github &&
                queryData.createdBy.socialProfile.github !== "" && (
                  <>
                    <Githublogo
                      href={queryData.createdBy.socialProfile.github}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <box-icon name="github" type="logo"></box-icon>
                    </Githublogo>
                  </>
                )}
            </FlexDiv1>
          ) : (
            <FlexDiv1>
              {queryData.createdBy.email &&
                queryData.createdBy.email !== "" && (
                  <>
                    <EmailIcon href={`mailto:${queryData.createdBy.email}`}>
                      <box-Icon name="envelope" type="logo"></box-Icon>
                    </EmailIcon>
                  </>
                )}
            </FlexDiv1>
          )}
        </FlexDiv>
        <FlexDiv mobcol margin5>
          <DetailsDiv domain>
            <Domain>Domain: </Domain>
            <Tagdiv start>
              {domain.map((domain, index) =>
                domain === "All" ? null : <Tag>{domain}</Tag>
              )}
            </Tagdiv>
          </DetailsDiv>
        </FlexDiv>
        {images.length > 0 && (
          <CoverFlex>
            {images.map((image, index) => (
              <>
              {model && <ImagePopup setModel={setModel} Image={img} />}
              <Coverimage
               src={image}
               onClick={() => {
                setImg(image);
                setModel(true);
              }}
               alt="query cover" />
               </>
            ))}
          </CoverFlex>
        )}
        <BodyContent>
          <Body
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(queryData.body),
            }}
          ></Body>
        </BodyContent>
        <FlexDiv1 marginbottom>
          <IconDiv>
            <FacebookShareButton url={pageURL}>
              <FacebookIcon size={30} round />
            </FacebookShareButton>
            <TwitterShareButton url={pageURL}>
              <TwitterIcon size={30} round />
            </TwitterShareButton>
            <WhatsappShareButton url={pageURL}>
              <WhatsappIcon size={30} round />
            </WhatsappShareButton>
          </IconDiv>
        </FlexDiv1>
        <CommentForm replybox>
          <CommentDiv none space>
            {user.photo ? (
              <ProfilePic src={user.photo} />
            ) : (
              <>
                {user.isLoggedIn && (
                  <PseudoProfile
                    dangerouslySetInnerHTML={{
                      __html: user.name.toUpperCase().split("")[0],
                    }}
                  />
                )}
              </>
            )}
            <CommentText
              placeholder="Add an answer"
              onChange={(e) => {
                setData({ ...data, message: e.target.value });
              }}
              value={data.message}
            />
          </CommentDiv>
          <Submitbutton
            type="button"
            onClick={() =>
              user.isLoggedIn
                ? Comment()
                : toast.warn("Sign in to comment on the query")
            }
          >
            Post
          </Submitbutton>
        </CommentForm>
        <FlexDiv1 margintop>
          <Title smallfont>Answers :</Title>
          <Div>
            <Title lightfont>Sort By</Title>
            <Container>
              <Options>
                <box-icon name="slider"></box-icon>
                <OptionListDiv>
                  <OptionList onClick={() => setFilter("recent")}>
                    <box-icon name="time-five"></box-icon>
                    <OptionContent>Recent</OptionContent>
                  </OptionList>
                  <OptionList
                    type="submit"
                    onClick={() => {
                      setFilter("liked");
                    }}
                    className="delete"
                  >
                    <box-icon name="heart"></box-icon>
                    <OptionContent>Liked</OptionContent>
                  </OptionList>
                  <OptionList
                    type="submit"
                    onClick={() => {
                      setFilter("oldest");
                    }}
                  >
                    <box-icon name="history"></box-icon>
                    <OptionContent>Oldest</OptionContent>
                  </OptionList>
                </OptionListDiv>
              </Options>
            </Container>
          </Div>
        </FlexDiv1>
        {comment.length > 0 ? (
          <>
            {comment.map((comment, index) => (
              <>
                <FlexDiv between column mobcol padding border top w100>
                  <CommentProfile>
                    <CommentInfo>
                      <FlexDiv column none mobcol mobcenter logo>
                        {comment.author.profile ? (
                          <ProfilePic src={comment.author.profile} />
                        ) : (
                          <>
                            <PseudoProfile
                              comment
                              dangerouslySetInnerHTML={{
                                __html: comment.author.name
                                  .toUpperCase()
                                  .split("")[0],
                              }}
                            />
                          </>
                        )}
                      </FlexDiv>
                      <Commentor column start>
                        <AuthorText
                          className="graduate"
                          margin6
                          margcomment
                          comment
                          onClick={() => {
                            if (comment.author.roleModel === "Alumni") {
                              navigate(
                                `${ROUTES.ALUMNIPROFILE.split(":")[0]}${
                                  comment.author.role.slug
                                }`
                              );
                            } else {
                              navigate(
                                `${ROUTES.PROFILE.split(":")[0]}${
                                  comment.author.role.slug
                                }`
                              );
                            }
                          }}
                        >
                          {comment.author.name}
                          {comment.author.roleModel === "Alumni" && (
                            <box-icon
                              type="solid"
                              name="graduation"
                              size="sm"
                            ></box-icon>
                          )}
                        </AuthorText>
                        {valueCrossed(
                          parseInt(comment.date.slice(5, 7)),
                          currentDate.getMonth() + 1
                        ) ? (
                          <Time margside>
                            {new Date(comment.date).toDateString().slice(4, 7) +
                              " " +
                              date +
                              " " +
                              year}
                          </Time>
                        ) : valueCrossed(
                            new Date(comment.date).getDate(),
                            currentDate.getDate()
                          ) ? (
                          <Time margside>
                            {currentDate.getDate() -
                              new Date(comment.date).getDate() +
                              "d ago"}
                          </Time>
                        ) : valueCrossed(
                            new Date(comment.date).getHours(),
                            currentDate.getHours()
                          ) ? (
                          <Time margside>
                            {currentDate.getHours() -
                              new Date(comment.date).getHours() +
                              "h ago"}
                          </Time>
                        ) : valueCrossed(
                            new Date(comment.date).getMinutes(),
                            currentDate.getMinutes()
                          ) ? (
                          <Time margside>
                            {currentDate.getMinutes() -
                              new Date(comment.date).getMinutes() +
                              "m ago"}
                          </Time>
                        ) : (
                          <Time margside>Just now</Time>
                        )}
                      </Commentor>
                    </CommentInfo>
                    {user.email === comment.author.email && (
                      <DeleteLogo
                        onClick={() => {
                          commentDelete(comment._id);
                        }}
                        className="delete"
                      >
                        <box-icon name="trash" type="solid"></box-icon>
                      </DeleteLogo>
                    )}
                  </CommentProfile>
                  <QueryContent column start none>
                    <Body comment>{comment.message}</Body>
                    <FlexDiv1 margside>
                      <LightText flex part pointer>
                        <LikedLogo
                          onClick={() =>
                            user.isLoggedIn
                              ? upvote(
                                  comment.upVotes.includes(userId),
                                  comment._id
                                )
                              : toast.warn("Sign in to like the query")
                          }
                        >
                          <box-icon
                            type="solid"
                            name="heart"
                            size="xs"
                            color={
                              comment.upVotes.includes(userId)
                                ? "#fe5d5d"
                                : "#e5e5e5"
                            }
                          ></box-icon>
                        </LikedLogo>
                        Like
                      </LightText>
                      <LightText
                        pointer
                        onClick={() => {
                          replyBox(index);
                        }}
                        className="replyText"
                      >
                        Reply
                      </LightText>
                      <HiddenLightText
                        none
                        pointer
                        onClick={() => {
                          closeReplyBox(index);
                        }}
                        className="closeReply"
                      >
                        Hide Reply
                      </HiddenLightText>
                    </FlexDiv1>
                    <ReplyForm className="replybox-hidden" replybox>
                      <ReplyDiv>
                        <CommentText
                          replybox
                          placeholder="Reply here"
                          onChange={(e) => {
                            setReplyData({
                              ...replyData,
                              message: e.target.value,
                              id: comment._id,
                            });
                          }}
                          onKeyDown={(e) =>
                            e.key === "Enter"
                              ? e.preventDefault() || user.isLoggedIn
                                ? Reply(index)
                                : toast.warn("Sign in to comment on the query")
                              : null
                          }
                          className="reply"
                        />
                      </ReplyDiv>
                    </ReplyForm>
                    <FlexDiv1 margside>
                      {comment.reply.length > 0 && (
                        <SmallText flex pointer none>
                          <ReplyLogo>
                            <box-icon
                              color={"#e5e5e5"}
                              type='solid' name='share'
                              rotate="180"
                            ></box-icon>
                          </ReplyLogo>
                          {comment.reply.length} Reply
                        </SmallText>
                      )}
                      <HiddenLightText
                        reply
                        pointer
                        onClick={() => {
                          ReplyHide(index);
                        }}
                        className="hideReply"
                      >
                        Hide Replies
                      </HiddenLightText>
                      {comment.reply.length === 0 ? (
                        <SmallText
                          hide
                          pointer
                          onClick={() => {
                            ReplyShow(index);
                          }}
                          className="showReplies"
                        >
                          Show Replies
                        </SmallText>
                      ) : (
                        <SmallText
                          pointer
                          onClick={() => {
                            ReplyShow(index);
                          }}
                          className="showReplies"
                        >
                          Show Replies
                        </SmallText>
                      )}
                    </FlexDiv1>
                  </QueryContent>
                  <QueryDiv column className="showReply">
                    {comment.reply.map((reply, index) => (
                      <div>
                        <CommentProfile>
                          <CommentInfo>
                            <FlexDiv column none mobcol mobcenter logo>
                              {reply.author.profile ? (
                                <ProfilePic src={reply.author.profile} />
                              ) : (
                                <>
                                  <PseudoProfile
                                    comment
                                    dangerouslySetInnerHTML={{
                                      __html: reply.author.name
                                        .toUpperCase()
                                        .split("")[0],
                                    }}
                                  />
                                </>
                              )}
                            </FlexDiv>
                            <Commentor column start>
                              <AuthorText
                                className="graduate"
                                margin6
                                margcomment
                                comment
                                onClick={() => {
                                  if (reply.author.roleModel === "Alumni") {
                                    navigate(
                                      `${ROUTES.ALUMNIPROFILE.split(":")[0]}${
                                        reply.author.role.slug
                                      }`
                                    );
                                  } else {
                                    navigate(
                                      `${ROUTES.PROFILE.split(":")[0]}${
                                        reply.author.role.slug
                                      }`
                                    );
                                  }
                                }}
                              >
                                {reply.author.name}
                                {reply.author.roleModel === "Alumni" && (
                                  <box-icon
                                    type="solid"
                                    name="graduation"
                                    size="sm"
                                  ></box-icon>
                                )}
                              </AuthorText>
                              {valueCrossed(
                                parseInt(reply.date.slice(5, 7)),
                                currentDate.getMonth() + 1
                              ) ? (
                                <Time margside>
                                  {new Date(reply.date)
                                    .toDateString()
                                    .slice(4, 7) +
                                    " " +
                                    date +
                                    " " +
                                    year}
                                </Time>
                              ) : valueCrossed(
                                  new Date(reply.date).getDate(),
                                  currentDate.getDate()
                                ) ? (
                                <Time margside>
                                  {currentDate.getDate() -
                                    new Date(reply.date).getDate() +
                                    "d ago"}
                                </Time>
                              ) : valueCrossed(
                                  new Date(reply.date).getHours(),
                                  currentDate.getHours()
                                ) ? (
                                <Time margside>
                                  {currentDate.getHours() -
                                    new Date(reply.date).getHours() +
                                    "h ago"}
                                </Time>
                              ) : valueCrossed(
                                  new Date(reply.date).getMinutes(),
                                  currentDate.getMinutes()
                                ) ? (
                                <Time margside>
                                  {currentDate.getMinutes() -
                                    new Date(reply.date).getMinutes() +
                                    "m ago"}
                                </Time>
                              ) : (
                                <Time margside>Just now</Time>
                              )}
                            </Commentor>
                          </CommentInfo>
                          {user.email === reply.author.email && (
                            <DeleteLogo
                              onClick={() => {
                                replyDelete(comment._id, reply._id);
                              }}
                              className="delete"
                            >
                              <box-icon name="trash" type="solid"></box-icon>
                            </DeleteLogo>
                          )}
                        </CommentProfile>
                        <QueryContent column start>
                          <Body comment>{reply.message}</Body>
                        </QueryContent>
                      </div>
                    ))}
                  </QueryDiv>
                </FlexDiv>
              </>
            ))}
          </>
        ) : (
          <Empty empty>
            <EmptyImg>
              <img src={empty} alt="Empty"></img>
            </EmptyImg>
            <EmptyMsg> No Answers yet </EmptyMsg>
          </Empty>
        )}
        {user.role === "Alumni" ? null : (
          <SubTitleDiv>
            <SubTitle>Not the answer you're looking for?</SubTitle>
            <SubTitleLink
              onClick={() => {
                navigate(ROUTES.CREATEQUERY);
              }}
            >
              ask your own question
            </SubTitleLink>
          </SubTitleDiv>
        )}
      </MainWrapper>
    </>
  );
};

export default EachQuery;
