import * as ROUTES from "./routes";

export const NAV = [
  {
    to: ROUTES.MYEVENT,
    icon: "calendar-plus",
    name: "My Events",
    role: ["Team"],
  },
  {
    to: ROUTES.MYANNOUNCEMENT,
    icon: "megaphone",
    name: "My Announcements",
    role: ["Team" , "Council", "Alumni"],
  },
  {
    to: ROUTES.MYBLOG,
    icon: "pen",
    name: "My Blogs",
    role: ["Student", "Team", "Alumni"],
  },
  {
    to: ROUTES.UPLOADNOTES,
    icon: "bookmark",
    name: "My Notes",
    role: ["Student"], 
  },
  {
    to: ROUTES.MYQUERY,
    icon: "question-mark",
    name: "My Query",
    role: ["Student"], 
  },
  {
    to:ROUTES.FORMS,
    icon: "bookmark-alt",
    name:"My Forms",
    role:["Team"],
  }
];
