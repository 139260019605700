import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing:border-box;
    scroll-padding: 60px;
    @media ${(props) => props.theme.MediaQueries.l.query} {
      scroll-padding: 0px;
    }
  }

  body {
    padding:0;
    margin:0;
    background:${(props) => props.theme.Colors.PageBg};
  }

  a {
      text-decoration:none;
  }

  a:hover {
    text-decoration:none;
  }

  ul {
    margin:0;
    padding:0;
    list-style:none;
  }

  img {
    max-width:100%;
      height:auto;
  }

  h1,h2,h3,h4,h5,h6{
    margin:0;
    padding:0;
  }

  main {
    padding: 64px 0 80px 0;
    margin: 0 auto;
    @media ${(props) => props.theme.MediaQueries.m.query} {
      padding: 64px 15px 80px 0px;
      max-width: 768px;
    }
    @media ${(props) => props.theme.MediaQueries.l.query} {
      padding: 64px 15px 80px 73px;
      max-width: 1400px;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background:  ${(props) => props.theme.Colors.ScrollBg};
    width: 10px;
    border-radius: 0px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background:  ${(props) => props.theme.Colors.ScrollThumbBg};
    border-radius: 10px;
    width: 10px;
    height: 6px;
  }
`;

export default GlobalStyle;
