import React from "react";
import styled from "styled-components";
import warning from "../Images/warning.png";

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #00000094;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding: 64px 0 0 52px;
  }
`;

const PopupDiv = styled.div`
  z-index: 10;
  position: fixed;
  width: 90%;
  max-width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${(props) => props.theme.Fonts.Inter};
  background: ${(props) => props.theme.Colors.PageBg};
  border: 1px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  box-shadow: ${(props) => props.theme.Colors.Shadow};
  border-radius: 8px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    max-width: 400px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    max-width: 500px;
  }
`;

const Warning = styled.img`
  height: 24px;
  width: auto;
  margin-right: 16px;
`;
const FlexDiv = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.start ? "flex-start" : "flex-end")};
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding: 16px 24px;
  }
`;
const Heading = styled.h1`
  color: ${(props) => props.theme.Colors.PopUpText};
  font-size: 16px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 19.2px;
  }
`;
const SubHeading = styled.h1`
  padding: 8px 16px;
  color: ${(props) => props.theme.Colors.PopUpText};
  font-size: 12.8px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
    padding: 16px 24px;
  }
`;
const CancelButton = styled.button`
  cursor: pointer;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 12.8px;
  border: none;
  background: none;
  padding: 8px 24px;
  color: ${(props) => props.theme.Colors.BodyText}; 
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
  }
`;

const DeleteButton = styled.button`
  cursor: pointer;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 12.8px;
  background: #ff5d61;
  border: none;
  border-radius: 8px;
  padding: 8px 24px;
  color: #fff;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 16px;
  }
`;

const Line = styled.hr`
  border: 0.01px solid ${(props) => props.theme.Colors.PlaceholderBorder};
  margin: 0;
`;

const Popup = ({ setTrigger, type, name, deletes}) => {
  return (
    <Modal>
      <PopupDiv>
        <FlexDiv start>
          <Warning src={warning} alt="warning" />
          <Heading>Confirm Delete</Heading>
        </FlexDiv>
        <Line />
        <SubHeading>
          Are you sure you want to delete {type} {name} ?
        </SubHeading>
        <FlexDiv>
          <CancelButton
            onClick={() => {
              setTrigger(false);
            }}
          >
            Cancel
          </CancelButton>
          <DeleteButton
            onClick={() => {
              deletes();
              setTrigger(false);
            }}
          >
            Delete
          </DeleteButton>
        </FlexDiv>
      </PopupDiv>
    </Modal>
  );
};

export default Popup;
