module.exports.subjectList = {
  Sem1: [
    "Engineering Mathematics-1 - EM1",
    "Engineering Physics-1 - EP1",
    "Engineering Chemistry-1 - EC1",
    "Engineering Mechanics - EM",
    "Basic Electrical Engineering - BEE",
    "Engineering Mathematics-1 - EM1 TUTORIAL",
    "Engineering Physics-1 - EP1 LAB",
    "Engineering Chemistry-1 - EC1 LAB",
    "Engineering Mechanics - EM LAB",
    "Basic Electrical Engineering - BEE LAB",
    "Basic Workshop practice-1",
  ],
  Sem2: [
    "Engineering Mathematics-2 - EM2",
    "Engineering Physics-2 - EP2",
    "Engineering Chemistry-2 - EC2",
    "Engineering Graphics - EG",
    "C programming - CP",
    "Professional Communication and Ethics-1 - PCE1",
    "Engineering Mathematics-2 - EM2 TUTORIAL",
    "Engineering Physics-2 - EP2 LAB",
    "Engineering Chemistry-2 - EC2 LAB",
    "Engineering Graphics - EG LAB",
    "C programming - CP LAB",
    "Professional Communication and Ethics-1 - PCE1 TUTORIAL",
    "Basic Workshop practice-2",
  ],
  Sem3_IT: [
    "Engineering Mathematics-3 - EM3",
    "Data Structure and Analysis - DSA",
    "Database Management System - DMS",
    "Principle of Communication - PCOM",
    "Paradigms and Computer Programming Fundamentals - PCPF",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Data Structure Lab - DS",
    "SQL Lab",
    "Computer programming Paradigms Lab - CPP",
    "Java Lab",
  ],
  Sem4_IT: [
    "Engineering Mathematics-4 - EM4",
    "Computer Network and Network Design - CNND",
    "Operating System - OS",
    "Automata Theory - AT",
    "Computer Organization and Architecture - COA",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Network Lab",
    "Unix Lab",
    "Microprocessor Lab",
    "Python Lab",
  ],
  Sem5_IT: [
    "Internet Programming - IP",
    "Advanced Data Management Technology - ADMT",
    "Computer Network Security- CNS",
    "Advance Data Structures and Algorithms - ADSA",
    "Professional Communication and Ethics - PCE",
    "Software Engineering - SE",
    "Professional Communication and Ethics Tutorial - PCE",
    "Internet Programming Lab - IP",
    "Security Lab - SL",
    "DevOps Lab - DevOps",
    "Advance DevOps Lab - ADO",
  ],
  Sem6_IT: [
    "Data Mining & Business Intelligence - DMBI",
    "Web X.0 - WebX",
    "Wireless Technology - WT",
    "AI and DS - AIDS",
    "Image Processing - IP",
    "Ethical Hacking and Forensic - EHF",
    "BI Lab - BI",
    "Web Lab",
    "Sensor Lab - SL",
    "MAD & PWA Lab - MAD",
    "DS using Python Lab - DSL",
  ],
  Sem7_IT: [
    "Enterprise Network Design - END",
    "Infrastructure Security - IS",
    "Artificial Intelligence - AI",
    "Mobile Application Development - MAD",
    "Software Testing and Quality Assurance - STQA",
    "Product Lifecycle Management - PLM",
    "Reliability Engineering - RE",
    "Management Information System - MIS",
    "Design Of Experiments - DE",
    "Operation Research - OR",
    "Cyber Security and Laws - CSL",
    "Disaster Management and Mitigation Measures - DMMM",
    "Energy Audit and Management - EAM",
    "Development Engineering - DE",
    "Network Design Lab - NWD",
    "Advanced Security Lab - AS",
    "Intelligence System Lab - IS",
    "Android Apps Development Lab - AAD",
  ],
  Sem8_IT: [
    "Big Data Analytics - BDA",
    "Internet of Everything - IE",
    "Big Data Lab - BDL",
    "Internet of Everything Lab - IEL",
    "DevOps Lab - DOL",
    "R Programming Lab - RPL",
    "User Interaction Design - UID",
    "Information Retrieval Systems - IRS",
    "Knowledge Management - KM",
    "Robotics - R",
    "Enterprise Resource Planning - ERP",
    "Project Management - PM",
    "Finance Management - FM",
    "Entrepreneurship Development and Management - EDM",
    "Human Resource Management - HRM",
    "Professional Ethics and CSR - PEC",
    "Research Methodology - RM",
    "IPR and Patenting - IP",
    "Digital Business Management - DBM",
    "Environmental Management - EM",
  ],
  Sem3_CE: [
    "Engineering Mathematics-3 - EM3",
    "Discrete Structures and Graph Theory - DSGT",
    "Data Structure - DS",
    "Digital Logic and Computer Architecture - DLCA",
    "Computer Graphics - CG",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Data Structure Lab - DS",
    "Digital Logic & Computer Architecture Lab - DLCA",
    "Computer Graphics Lab - CG",
    "Object Oriented Programming with Java Lab",
  ],
  Sem4_CE: [
    "Engineering Mathematics-4 - EM4",
    "Analysis of Algorithm - AA",
    "Database Management System - DMS",
    "Operating System - OS",
    "Microprocessor - MP",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Analysis of Algorithm Lab - AA",
    "Database Management System Lab - DMS",
    "Operating System Lab - OS",
    "Microprocessor Lab - MP",
    "Python Programming",
  ],
  Sem5_CE: [
    "Theoretical Computer Science - TCS",
    "Software Engineering - SE",
    "Computer Network - CN",
    "Data Warehousing & Mining - DWM",
    "Probabilistic Graphical Models",
    "Internet Programming",
    "Advance Data Management System",
    "Professional Communication and Ethics - PCE",
    "Software Engineering Lab - SE",
    "Computer Network Lab - CN",
    "Data Warehousing & Mining Lab - DWM",
    "Professional Communication and Ethics Tutorial - PCE",
  ],
  Sem6_CE: [
    "System Programming & Compiler Construction - SPCC",
    "Cryptography & System Security - CSS",
    "Mobile Computing - MC",
    "Artificial Intelligence - AI",
    "Internet of Things",
    "Digital Signal & Image Processing",
    "Quantitative Analysis",
    "System Programming & Compiler Construction Lab - SPCC",
    "Cryptography & System Security Lab - CSS",
    "Mobile Computing Lab - MC",
    "Cloud Computing Lab - CC",
  ],
  Sem7_CE: [
    "Digital Signal and Image Processing - DSIP",
    "Mobile Communication and Computing - MCC",
    "Artificial Intelligence and Soft Computing - AISC",
    "Big Data Analysis - BDA",
    "Product Lifecycle Management - PLM",
    "Reliability Engineering - RE",
    "Management Information System - MIS",
    "Design Of Experiments - DE",
    "Operation Research - OR",
    "Cyber Security and Laws - CSL",
    "Disaster Management and Mitigation Measures - DMMM",
    "Energy Audit and Management - EAM",
    "Development Engineering - DE",
    "Digital Signal and Image Processing Lab - DSIP",
    "Mobile Application Development Technology Lab - MADT",
    "Artificial Intelligence and Soft Computing Lab - AISC",
    "Computational Lab-1 - CL1",
  ],
  Sem8_CE: [
    "Human Machine Interaction - HMI",
    "Distributed Computing Course - DC",
    "Human Machine Interaction Lab - HMIL",
    "Distributed Computing Lab - DCL",
    "Cloud Computing Lab - CCL",
    "Computational Lab-II - CL2",
    "High Performance Computing - HPC",
    "Natural Language Processing - NLP",
    "Adhoc Wireless Network - AWN",
    "Project Management - PM",
    "Finance Management - FM",
    "Entrepreneurship Development and Management - EDM",
    "Human Resource Management - HRM",
    "Professional Ethics and CSR - PEC",
    "Research Methodology - RM",
    "IPR and Patenting - IP",
    "Digital Business Management - DBM",
    "Environmental Management - EM",
  ],
  Sem3_EXTC: [
    "Engineering Mathematics-3 - EM3",
    "Electronic Devices and Circuits - EDC",
    "Digital System Design - DSD",
    "Network Theory - NT",
    "Electronic Instrumentation and Control Systems - EICS",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Electronic Devices and Circuits Lab - EDC",
    "Digital System Design Lab - DSD",
    "Electronic Instrumentation and Control Systems Lab - EICS",
    "Cpp and Java Programming",
  ],
  Sem4_EXTC: [
    "Engineering Mathematics-4 - EM4",
    "Microcontrollers - MC",
    "Linear Integrated Circuits - LIC",
    "Signals and Systems - SS",
    "Principles of Communication Engineering - PCE",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Microcontrollers Lab - MC",
    "Linear Integrated Circuits Lab - LIC",
    "Principles of Communication Engineering Lab - PCE",
    "Python Programming",
  ],
  Sem5_EXTC: [
    "Digital Communication - DCOM",
    "Discrete Time Signal Processing - DTSP",
    "Professional Communication and Ethics - PCE",
    "Digital VLSI",
    "Data Structures and Algorithm - DSA",
    "Data Compression and Cryptography - DCC",
    "Random Signal Analysis - RSA",
    "Digital Communication Lab - DCOM",
    "Discrete Time Signal Processing Lab- DTSP",
    "Digital VLSI Lab",
    "Professional Communication and Ethics Tutorial - PCE",
  ],
  Sem6_EXTC: [
    "Electromagnetics and Antenna - EMA",
    "Computer Communication Networks - CCN",
    "Image Processing and Machine Vision - IPMV",
    "Artificial Neural Network and Fuzzy Logic - ANNFL",
    "Database Management System -DBMS",
    "Digital Forensic - DF",
    "Radar Engineering - RE",
    "Electromagnetics and Antenna Lab - EMA",
    "Computer Communication Networks Lab - CCN",
    "Image Processing and Machine Vision Lab - IPMV",
    "Linux and Networking and Server Configuration - LNSC",
  ],
  Sem7_EXTC: [
    "Optical Communication - OC",
    "Mobile Communication System - MCS",
    "Microwave Engineering - ME",
    "Neural Networks and Fuzzy Logic - NNFL",
    "Embedded Systems - ES",
    "Product Lifecycle Management - PLM",
    "Reliability Engineering - RE",
    "Management Information System - MIS",
    "Design Of Experiments - DE",
    "Operation Research - OR",
    "Cyber Security and Laws - CSL",
    "Disaster Management and Mitigation Measures - DMMM",
    "Energy Audit and Management - EAM",
    "Development Engineering - DE",
    "Optical Communication Lab - OC",
    "Microwave Engineering Lab - ME",
    "Mobile Communication System Lab - MCS",
    "Neural Networks and Fuzzy Logic Lab - NNFL",
    "Embedded Systems Lab - ES",
  ],
  Sem8_EXTC: [
    "RF Design - RFD",
    "Wireless Networks - WN",
    "RF Design Lab - RDL",
    "Wireless Networks Lab - WN",
    "Optical Networks - ON",
    "Advanced Digital Signal Processing - ADSP",
    "Satellite Communication - SC",
    "Network Management in Telecommunication - NMT",
    "Optical Networks Lab - ONL",
    "Advanced Digital Signal Processing Lab - ADSPL",
    "Satellite Communication Lab - SCL",
    "Network Management in Telecommunication Lab - NMTL",
    "Project Management - PM",
    "Finance Management - FM",
    "Entrepreneurship Development and Management - EDM",
    "Human Resource Management - HRM",
    "Professional Ethics and CSR - PEC",
    "Research Methodology - RM",
    "IPR and Patenting - IP",
    "Digital Business Management - DBM",
    "Environmental Management - EM",
  ],
  Sem3_ME: [
    "Engineering Mathematics-3 - EM3",
    "Strength of Materials - SM",
    "Production Processes - PP",
    "Materials and Metallurgy - MM",
    "Thermodynamics - TD",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Materials Testing Lab",
    "Machine Shop Practice",
  ],
  Sem4_ME: [
    "Engineering Mathematics-4 - EM4",
    "Fluid Mechanics - FM",
    "Kinematics of Machinery - KM",
    "CAD CAM",
    "Industrial Electronics - IE",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Industrial Electronics Lab - IE",
    "Kinematics of Machinery Lab - KM",
    "Python Programming",
  ],
  Sem5_ME: [
    "Mechanical Measurements and Controls - MMC",
    "Thermal Engineering - TE",
    "Dynamics of Machinery - DM",
    "Finite Element Analysis - FEA",
    "Optimization Techniques - OT",
    "Design of Experiments - DOE",
    "Professional Communication and Ethics - PCE",
    "Thermal Engineering Lab - TE",
    "Dynamics of Machinery Lab - DM",
    "Finite Element Analysis Lab - FEA",
    "Professional Communication and Ethics Tutorial - PCE",
  ],
  Sem6_ME: [
    "Machine Design - MD",
    "Turbo Machinery - TM",
    "Heating, Ventilation, Air Conditioning and Refrigeration - HVAR",
    "Automation and Artificial Intelligence - AAI",
    "Metal Forming Technology - MFT",
    "Press Tool Design - PTD",
    "Machine Design Lab- MD",
    "Turbo Machinery Lab- TM",
    "Heating, Ventilation, Air Conditioning and Refrigeration Lab- HVAR",
    "Measurements and Automation - MA",
  ],
  Sem7_ME: [
    "Machine Design-2 - MD",
    "CAD CAM CAE - CCC",
    "Production Planning and Control - PPC",
    "Automobile Engineering - AE",
    "Pumps, Compressor and Fans - PCF",
    "Product Lifecycle Management - PLM",
    "Operation Research - OR",
    "Machine Design-2 Lab",
    "CAD CAM CAE Lab - CCC",
    "Production Planning and Control Lab - PPC",
  ],
  Sem8_ME: [
    "Design of Mechanical Systems - DMS",
    "Industrial Engineering and Management - IEM",
    "Power Engineering - PE",
    "Design of Mechanical Systems Lab - DMSL",
    "Power Engineering Lab - PEL",
    "Power Plant Engineering - PPE",
    "Rapid Prototyping - RP",
    "Renewable Energy Systems - RES",
    "Energy Management in Utility Systems - EMUS",
    "Project Management - PM",
    "Finance Management - FM",
    "Entrepreneurship Development and Management - EDM",
    "Human Resource Management - HRM",
    "Professional Ethics and CSR - PEC",
    "Research Methodology - RM",
    "IPR and Patenting - IP",
    "Digital Business Management - DBM",
    "Environmental Management - EM",
  ],
  Sem3_PPT: [
    "Engineering Mathematics-3 - EM3",
    "Packaging Introduction and Concepts - PIC",
    "Introduction to Printing Technology - IPT",
    "Paper based Packaging Materials - PPM",
    "Glass, Metal and Textile based Packaging Materials - GMTPM",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Principles of Graphic Arts and Design-1 - PGAD",
    "Screen Printing Lab - SPL",
  ],
  Sem4_PPT: [
    "Engineering Mathematics-4 - EM4",
    "Plastics in Packaging - PP",
    "Colour Reproduction - CR",
    "Offset Printing - OP",
    "Digital Electronics and Microcontrollers - DEM",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Principles of Graphic Arts and Design-2 Lab - PGAD",
    "Colour Reproduction Lab - CR",
    "Offset Printing Lab - OP",
    "Digital Electronics and Microcontrollers Lab - DEM",
  ],
  Sem5_PPT: [
    "Plastics Processing & Conversion Technologies - PPCT",
    "Theory of Machines & Design - TMD",
    "Instrumentation & Process Control - IPC",
    "Ancillary Packaging Materials & Industrial Packaging",
    "Packaging Distribution Dynamics",
    "Ink and Coating",
    "Print finishing & Converting",
    "Additive Manufacturing (3D Printing)",
    "Professional Communication and Ethics - PCE",
    "Plastics Processing & Conversion Technologies Lab - PPCT",
    "Theory of Machines & Design Lab - TMD",
    "Instrumentation & Process Control Lab - IPC",
    "Professional Communication and Ethics Tutorial - PCE",
  ],
  Sem6_PPT: [
    "Packaging Machineries & Systems - PMS",
    "Food & Pharmaceutical Packaging - FPP",
    "Gravure Printing - GP",
    "Colour Management - CM",
    "Packaging Laws, Regulations & Sustainability",
    "Digital & Security Printing",
    "Financial & Marketing Management",
    "Project Management & Entrepreneurship",
    "Food & Pharmaceutical Packaging Lab - FPP",
    "Colour Management Lab - CM",
    "Package Design & Graphics II - PDG",
  ],
  Sem7_PPT: [
    "Financial and Marketing Management - FMM",
    "Project Management and Entrepreneurship - PME",
    "Laws, Regulations and Sustainability in Packaging - LRSP",
    "Total Quality Management - TQM",
    "Packaging Distribution and Logistics - PDL",
    "Advanced Food Packaging - AFP",
    "Advanced Industrial Products Packaging - AIPP",
    "Labelling Technology - LT",
    "Product Lifecycle Management - PLM",
    "Reliability Engineering - RE",
    "Management Information System - MIS",
    "Design Of Experiments - DE",
    "Operation Research - OR",
    "Cyber Security and Laws - CSL",
    "Disaster Management and Mitigation Measures - DMMM",
    "Energy Audit and Management - EAM",
    "Development Engineering - DE",
    "Packaging Distribution and Logistics Lab - PDLL",
    "Printing and Packaging Costing Tutorial - PPCT",
  ],
  Sem3_ECS: [
    "Engineering Mathematics-3 - EM3",
    "Electronic Devices - ED",
    "Digital Electronics - DE",
    "Data Structures and Algorithms - DSA",
    "Database Management Systems - DBMS",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Electronic Devices Lab - ED",
    "Digital Electronics Lab - DE",
    "Data Structures and Algorithms Lab - DSA",
    "Database Management Systems Lab - DBMS",
    "OOPM Lab",
  ],
  Sem4_ECS: [
    "Engineering Mathematics-4 - EM4",
    "Electronic Circuits - EC",
    "Controls and Instrumentation - CI",
    "Microprocessors and Microcontrollers - MM",
    "Discrete structures and Automata Theory - DS&AT",
    "Engineering Mathematics-4 - EM4 TUTORIAL",
    "Electronic Circuits Lab - EC",
    "Controls and Instrumentation Lab - CI",
    "Microprocessors and Microcontrollers Lab - MM",
    "Python Programming Lab",
  ],
  Sem5_ECS: [
    "Communication Engineering - CE",
    "Computer Organization and Architecture - COA",
    "Software Engineering - SE",
    "Web Technology - WT",
    "Information Theory and Coding - ITC",
    "Software Testing & Quality Assurance  - ST",
    "Professional Communication and ethics-2 - PCE2",
  ],
  Sem3_AIML: [
    "Engineering Mathematics-3 - EM3",
    "Discrete Structures and Graph Theory - DSGT",
    "Data Structure - DS",
    "Digital Logic and Computer Architecture - DLCA",
    "Computer Graphics - CG",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Data Structure Lab - DS",
    "Digital Logic & Computer Architecture Lab - DLCA",
    "Computer Graphics Lab - CG",
  ],
  Sem3_AIDS: [
    "Engineering Mathematics-3 - EM3",
    "Discrete Structures and Graph Theory - DSGT",
    "Data Structure - DS",
    "Digital Logic and Computer Architecture - DLCA",
    "Computer Graphics - CG",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Data Structure Lab - DS",
    "Digital Logic & Computer Architecture Lab - DLCA",
    "Computer Graphics Lab - CG",
  ],
  Sem3_CSE: [
    "Engineering Mathematics-3 - EM3",
    "Discrete Structures and Graph Theory - DSGT",
    "Data Structure - DS",
    "Digital Logic and Computer Architecture - DLCA",
    "Computer Graphics - CG",
    "Engineering Mathematics-3 - EM3 TUTORIAL",
    "Data Structure Lab - DS",
    "Digital Logic & Computer Architecture Lab - DLCA",
    "Computer Graphics Lab - CG",
  ],
};

module.exports.semester = [
  "Semester 1",
  "Semester 2",
  "Semester 3",
  "Semester 4",
  "Semester 5",
  "Semester 6",
  "Semester 7",
  "Semester 8",
];

module.exports.branch = [
  "ALL",
  "CE",
  "EXTC",
  "IT",
  "ME",
  "PPT",
  "ECS",
  "AIDS",
  "AIML",
  "CSE",
];

module.exports.year = ["FE", "SE", "TE", "BE", "ALL"];

module.exports.gender = ["Male", "Female", "Others"];

module.exports.fests = ["TML", "Cognition", "Regular"];

module.exports.academicYear = [
  "2021-22",
  "2022-23",
  "2023-24",
  "2024-25",
  "2025-26",
  "2026-27"
];

module.exports.teamId = [
  ",council_tech@siesgst.ac.in",
  "designteam@siesgst.ac.in",
  "sports@siesgst.ac.in",
  "council_cultural@siesgst.ac.in",
  "council_marketing@siesgst.ac.in",
  "nssevents@siesgst.ac.in",
  "socialmedia@siesgst.ac.in",
  "aaroha@siesgst.ac.in",
  "council_publicity@siesgst.ac.in",
  "teamastrionics@siesgst.ac.in",
  "photographyclub@siesgst.ac.in",
  "dsc@siesgst.ac.in",
  "csi-council@siesgst.ac.in",
  "ppack@siesgst.ac.in",
  "impressions.siesgst.ac.in",
  "ieee@siesgst.ac.in",
  "iete-council@siesgst.ac.in",
  "edc@siesgst.ac.in",
  "promethean@siesgst.ac.in",
  "pr@siesgst.ac.in ",
  "codechef@siesgst.ac.in",
  "council.sae@gmail.com",
  "team_turbocrafters@siesgst.ac.in",
  "theliteraryteam@siesgst.ac.in",
  "creativeteam@siesgst.ac.in",
  "regdesk.siesgst@gmail.com",
  "iste@siesgst.ac.in",
];

module.exports.line = ["Central", "Harbour", "TransHarbour", "Western", "Uran"];

module.exports.class = ["I", "II"];

module.exports.stationList = {
  Central: [
    "Ambernath",
    "Ambivili",
    "Asangaon",
    "Atgaon",
    "Badlapur",
    "Bhandup",
    "Bhiwandi",
    "Bhivpuri",
    "Byculla",
    "Chatrapati Shivaji Terminus",
    "Chinchpokli",
    "Currey Road",
    "Dadar",
    "Diva",
    "Dolavi",
    "Dombivli",
    "Ghatkopar",
    "Kalwa",
    "Kalyan",
    "Kanjurmarg",
    "Karjat",
    "Kasara",
    "Kelavi",
    "Khadavli",
    "Khardi",
    "Khopoli",
    "Kopar",
    "Kurla",
    "Lowjee",
    "Masjid",
    "Matunga",
    "Mulund",
    "Mumbra",
    "Nahur",
    "Neral",
    "Palasdari",
    "Parel",
    "Sandurst Road",
    "Shahad",
    "Shelu",
    "Sion",
    "Thakurli",
    "Thane",
    "Titwala",
    "Ulhasnagar",
    "Vangani",
    "Vasind",
    "Vidhyavihar",
    "Vikhroli",
    "Vithalwadi",
  ],
  Harbour: [
    "Andheri",
    "Bandra",
    "CBD Belapur",
    "Chatrapati Shivaji Terminus",
    "Chembur",
    "Chunabhatti",
    "Cotton Green",
    "Dockyard Road",
    "Govandi",
    "GTB Nagar",
    "Juinagar",
    "Khandeshwar",
    "Khar Road",
    "Kharghar",
    "Kings Circle",
    "Kurla",
    "Mahim Junction",
    "Mankhurd",
    "Mansarovar",
    "Masjid",
    "Panvel",
    "Reay Road",
    "Sandhurst Road",
    "Sanpada",
    "Santacruz",
    "Seawood Darave",
    "Sewri",
    "Tilaknagar",
    "Vashi",
    "Vile Parle",
    "Wadala Road",
  ],
  Western: [
    "Andheri",
    "Bandra",
    "Bhayandar",
    "Boisar",
    "Borivali",
    "Charni Road",
    "Churchgate",
    "Dadar",
    "Dahanu Road",
    "Dahisar",
    "Elphinstone Road",
    "Goregaon",
    "Grant Road",
    "Jogeshwari",
    "Kandivali",
    "Kelve Road",
    "Khar Road",
    "Lower Parel",
    "Mahalaxmi",
    "Mahim",
    "Malad",
    "Marine Lines",
    "Matunga Road",
    "Mira Road",
    "Mumbai Central",
    "Naigaon",
    "Nala Sopara",
    "Palghar",
    "Santacruz",
    "Saphale",
    "Umroli",
    "Valtarna",
    "Vangaon",
    "Vasai Road",
    "Vile Parle",
    "Virar",
  ],
  TransHarbour: [
    "Airoli",
    "Bamandongari",
    "CBD Belapur",
    "Digha Gaon",
    "Ghansoli",
    "Juinagar",
    "Khandeshwar",
    "Kharghar",
    "Kharkopar",
    "Koparkhairane",
    "Mansarovar",
    "Panvel",
    "Rabale",
    "Sanpada",
    "Seawoods Darave",
    "Thane",
    "Turbhe",
    "Vashi",
  ],
  Uran: ["Bamandongari", "CBD Belapur", "Kharkopar", "Seawoods Darave", "Uran", "Dronagiri", "Shematikhar", "Nhava Sheva"],
};

module.exports.employmentType = [
  "Self-Employed",
  "Start-up",
  "Full-Time",
  "Part-Time",
  "Higher-Studies",
  "Unemployed",
  "Other",
];

module.exports.domain = [
  // "All",
  "Web",
  "App",
  "Data Science",
  "UI/UX",
  "ML",
  "AI",
  "Cyber Security",
  "Blockchain",
  "AR/ VR",
  "Cloud Computing",
  "Social media",
  "Marketing",
  "Video Editing",
  "Data Structures",
  "Competitive Coding",
  "Other",
];

module.exports.Querydomain = [
  "All",
  "Web",
  "App",
  "Data Science",
  "UI/UX",
  "ML",
  "AI",
  "Cyber Security",
  "Blockchain",
  "AR/ VR",
  "Cloud Computing",
  "Social media",
  "Marketing",
  "Video Editing",
  "Data Structures",
  "Competitive Coding",
  "Other",
];

module.exports.fieldType=[ 
  "checkbox",
  "date",
  "email",
  "radio",
  "text",
  "time",
  "url",
  "number",
  "textarea",
  "dropdown",
];