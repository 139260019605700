import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Pagination from "../Components/CommonComponents/Pagination";
import Loader from "../Components/CommonComponents/Loader";
import QueryCard from "../Components/QueryCard";
import empty from "../Images/CommonComponents/Empty.svg";
import { toast } from "react-toastify";
import Jumbotron from "../Components/CommonComponents/Jumbotron";
import { getMyQuery } from "../Services/alumni";

const QueryWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 340px;
  margin: 0.5rem;
  margin: auto;

  @media ${(props) => props.theme.MediaQueries.s.query} {
    // width: 576px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    // width: 768px;
    width: 58vw;
    // padding-top: 64px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 58vw;
    // margin-left: 1.5rem;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 65vw;
  }
`;

const Div = styled.main`
  max-width: unset;
  padding-right: 0;
  padding-bottom: 20px !important;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 53px;
  }
`;

const Empty = styled.div`
  min-width: 100%;
`;

const EmptyImg = styled.div`
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
  }
`;

const EmptyMsg = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  color: ${(props) => props.theme.Colors.LabelText};
`;

const Page = styled.div``;

const MyQuery = () => {
  const [response, setResponse] = useState([]);
  const [value, setValue] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  let limit = 5;
  let currentDate = new Date();
  let displayTime;

  const valueCrossed = (name, value) => {
    if (value === name) {
      return false;
    } else {
      return true;
    }
  };

  const pageChange = (pageNo) => {
    setPage(pageNo);
  };

  useEffect(() => {
    document.title = "My Query";
    setValue(false);
    setLoading(true);
    getMyQuery(page, limit)
      .then((result) => {
        setResponse(result.data.data.result);
        setTotalPages(result.data.data.totalPage);
        setLoading(false);
      })
      .catch((err) => {
        setResponse(null);
        setTotalPages(0);
        setLoading(false);
        // toast.warn(err.response.data.message);
      });
  }, [page, limit, value]);

  return (
    <>
      <Div>
        {loading && <Loader />}
        <Jumbotron
          disabled={true}
          label="My Query"
          BtnQuery
          ButtonTxt="Ask Query"
          Alumni
        />
        <QueryWrapper>
          {response ? (
            <LeftSection>
              {response.map((data, index) => {
                let year = new Date(data.createdAt).getFullYear();
                let month = parseInt(data.createdAt.slice(5, 7));
                let date = new Date(data.createdAt).getDate();
                let hours = new Date(data.createdAt).getHours();
                let minutes = new Date(data.createdAt).getMinutes();

                valueCrossed(month, currentDate.getMonth() + 1)
                  ? (displayTime =
                      new Date(data.createdAt).toDateString().slice(4, 7) +
                      " " +
                      date +
                      " " +
                      year)
                  : valueCrossed(date, currentDate.getDate())
                  ? (displayTime = currentDate.getDate() - date + "d ago")
                  : valueCrossed(hours, currentDate.getHours())
                  ? (displayTime = currentDate.getHours() - hours + "h ago")
                  : valueCrossed(minutes, currentDate.getMinutes())
                  ? (displayTime = currentDate.getMinutes() - minutes + "m ago")
                  : (displayTime = "just now");

                return (
                  <QueryCard
                    key={index}
                    title={data.query}
                    description={data.body}
                    name={data.createdBy.name}
                    domain={data.domain}
                    image={data.images[0]}
                    userData={data.createdBy}
                    time={displayTime}
                    slug={data.slug}
                    setValue={setValue}
                    status={data.openQueryStatus}
                  />
                );
              })}
            </LeftSection>
          ) : (
            <Empty empty>
              <EmptyImg>
                <img src={empty} alt="Empty"></img>
              </EmptyImg>
              <EmptyMsg>No queries found</EmptyMsg>
            </Empty>
          )}
        </QueryWrapper>
        <Page>
          <Pagination
            pageChange={pageChange}
            totalPages={totalPages}
            page={page}
          />
        </Page>
      </Div>
    </>
  );
};

export default MyQuery;
