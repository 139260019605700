import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Pagination from "../Components/CommonComponents/Pagination";
import { getAllQuery } from "../Services/alumni";
import Loader from "../Components/CommonComponents/Loader";
import QueryCard from "../Components/QueryCard";
import * as constant from "../Constants/constant";
import empty from "../Images/CommonComponents/Empty.svg";
import { toast } from "react-toastify";
import DiwaliImg from "../Images/DiwaliImg.jpg"
import ALumniBg from "../Images/CogBg.png";
import SearchBar from "../Components/CommonComponents/BlogsSearchBar";

const Option = styled.option`
  /* color: ${(props) => props.theme.Colors.PlaceholderText}; */
  color: #FFFFFF;
  background-color: ${(props) => props.theme.Colors.PlaceholderBg};
  font-family: ${(props) => props.theme.Fonts.Inter};
  cursor: pointer;
  font-size: 16px;
  outline: 0.7px solid ${(props) => props.theme.Colors.PlaceholderBorder};
`;

const Select = styled.select`
  width: ${(props) => (props.sem ? "96px" : "192px")};
  cursor: pointer;
  color: #ffffff;
  background-color: transparent;
  /* height: 48px; */
  padding: 3px 0 3px 0;
  border: none;
  &:focus {
    outline: none;
  }
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  & option {
    background-color: ${(props) => props.theme.Colors.PlaceholderBg};
  }
`;

const QueryWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  margin: auto;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    width: 58vw;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 58vw;
  }
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: 65vw;
  }
`;

const Button = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.Colors.White};
  border-radius: 40px;
  padding: 0.3;
  border: none;
  max-width: 160px;
  width: 112px;
  height: 54.4px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ff9f0f;
  & box-icon {
    fill: ${(props) => props.theme.Colors.White};
  }
`;
const HeadingBody = styled.p`
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: #ffffff;
  font-size: 12.8px;
  font-weight: 700;
  text-align: center;
  padding: 0 32px 0;
  margin-bottom: 30px;
  margin-top: 10px;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    font-size: 16px;
    padding: 0 56px 0;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 18px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 22px;
    padding: 0 80px 0;
    margin-bottom: 50px;
  }
`;

const Div = styled.main`
  max-width: unset;
  padding-right: 0;
  padding-bottom: 20px !important;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-left: 53px;
  }
`;

const Page = styled.div``;

const Empty = styled.div`
  margin: 0 auto;
`;

const EmptyImg = styled.div`
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.MediaQueries.l.query} {
    width: 300px;
  }
`;

const EmptyMsg = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-size: 14px;
  color: ${(props) => props.theme.Colors.LabelText};
`;

const JumbotronContainer = styled.div`
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 16px 16px;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    height: 360px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    height: 408px;
    border-radius: 0 0 32px 32px;
  }
`;

const JumbotronContent = styled.div`
  padding: 48px 0 48px;
  text-align: center;
  @media ${(props) => props.theme.MediaQueries.m.query} {
    padding-top: 128px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    padding-top: 112px;
  }
`;

const HeadingLabel = styled.h1`
  font-family: ${(props) => props.theme.Fonts.Quicksand};
  color: ${(props) => props.theme.Colors.White};
  font-size: 22.4px;
  font-weight: 700;
  text-align: center;
  padding: 0 24px 0;
  @media ${(props) => props.theme.MediaQueries.s.query} {
    font-size: 25.6px;
  }
  @media ${(props) => props.theme.MediaQueries.m.query} {
    font-size: 28.8px;
  }
  @media ${(props) => props.theme.MediaQueries.l.query} {
    font-size: 45px;
  }
`;

const Search = styled.div`
  display: ${(props) => (props.mobile ? "block" : "none")};
  @media ${(props) => props.theme.MediaQueries.l.query} {
    display: ${(props) => (props.laptop ? "block" : "none")};
  }
`;

const Div2 = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    margin-right: 30px;
  }
`;

const SearchBox = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.12);
  border: 1px solid transparent;
  padding: 10px 10px 10px 40px;
  border-radius: 100px;
`;

const Input = styled.input`
  &:focus {
    outline: none;
    border-bottom: 1px solid #bde7ff;
  }

  max-width: 320px;
  /* height: 48px; */
  border: none;
  font-size: 16px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 3px 0 2px 0;
  border-bottom: 1px solid #ffffff;

  width: ${(props) => (props.event ? "160px" : "128px")};
  color: ${(props) => props.theme.Colors.HeaderText};
  background-color: transparent;
  @media ${(props) => props.theme.MediaQueries.xl.query} {
    width: ${(props) => (props.event ? "208px" : "160px")};
  }
  &::placeholder {
    color: #ffffff;
  }
`;

const Parameter = styled.h1`
  font-family: ${(props) => props.theme.Fonts.Inter};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
`;

const Form = styled.form`
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 16px 0;
`;

const Flex = styled.div`
  display: flex;
`;

const AllQueries = () => {
  const [value, setValue] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("All");
  const [name, setName] = useState("");
  const [searchData, setSearchData] = useState("");

  const [toggle, setToggle] = useState(false);
  const [queryData, setqueryData] = useState([]);
  let limit = 5;

  let currentDate = new Date();
  let displayTime;

  const valueCrossed = (name, value) => {
    if (value === name) {
      return false;
    } else {
      return true;
    }
  };

  const pageChange = (pageNo) => {
    setPage(pageNo);
  };

  useEffect(() => {
    document.title = "GST Forum";
    setValue(false);
    setLoading(true);
    getAllQuery(name, domain, page, limit)
      .then((result) => {
        setqueryData(result.data.data.result);
        setTotalPages(result.data.data.totalPage);
        if (result.data.data.totalPage === 1) {
          setPage(1);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(false);
      })
      .catch((err) => {
        setqueryData(null);
        setTotalPages(0);
        setLoading(false);
        // toast.warn(err.response.data.message);
      });
  }, [page, name, value, limit, domain]);

  return (
    <>
      <Div>
        {loading && <Loader />}
        <JumbotronContainer
          style={{
            backgroundImage: `url(${ALumniBg})`,
          }}
        >
          <JumbotronContent>
            <HeadingLabel>GST Forum</HeadingLabel>
            <HeadingBody>
              Interact with our current GSTians through our GST Forum and
              address their queries through your invaluable experience.
            </HeadingBody>
            <Search mobile>
              <SearchBar
                placeholder="Search for title"
                setSearchData={setName}
                setToggle={() => setToggle(() => !toggle)}
              />
            </Search>
            <Search laptop>
              <Form>
                <SearchBox>
                  <Flex>
                    <Div2>
                      <Parameter>Title</Parameter>
                      <Input
                        event
                        placeholder="What is the title?"
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                    </Div2>
                    <Div2>
                      <Parameter>Domain</Parameter>
                      <Select
                        id="selectDomain"
                        className="domain"
                        onChange={(e) => setDomain(e.target.value)}
                      >
                        {constant.Querydomain.map((domainOption, index) => (
                          <Option value={domainOption}>{domainOption} </Option>
                        ))}
                      </Select>
                    </Div2>
                    <div>
                      <Button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggle(() => !toggle);
                          setName(searchData);
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </Flex>
                </SearchBox>
              </Form>
            </Search>
          </JumbotronContent>
        </JumbotronContainer>
        <QueryWrapper>
          {queryData ? (
            <LeftSection>
              {queryData.map((queryData, index) => {
                let year = new Date(queryData.createdAt).getFullYear();
                let month = parseInt(queryData.createdAt.slice(5, 7));
                let date = new Date(queryData.createdAt).getDate();
                let hours = new Date(queryData.createdAt).getHours();
                let minutes = new Date(queryData.createdAt).getMinutes();

                valueCrossed(month, currentDate.getMonth() + 1)
                  ? (displayTime =
                      new Date(queryData.createdAt).toDateString().slice(4, 7) +
                      " " +
                      date +
                      " " +
                      year)
                  : valueCrossed(date, currentDate.getDate())
                  ? (displayTime = currentDate.getDate() - date + "d ago")
                  : valueCrossed(hours, currentDate.getHours())
                  ? (displayTime = currentDate.getHours() - hours + "h ago")
                  : valueCrossed(minutes, currentDate.getMinutes())
                  ? (displayTime = currentDate.getMinutes() - minutes + "m ago")
                  : (displayTime = "just now");

                return (
                  <QueryCard
                    disabled={true}
                    name={queryData.createdBy.name}
                    description={queryData.body}
                    title={queryData.query}
                    time={displayTime}
                    slug={queryData.slug}
                    domain={queryData.domain}
                    image={queryData.images[0]}
                    userData={queryData.createdBy}
                    setValue={setValue}
                    status={queryData.openQueryStatus}
                  />
                );
              })}
            </LeftSection>
          ) : (
            <Empty empty>
              <EmptyImg>
                <img src={empty} alt="Empty"></img>
              </EmptyImg>
              <EmptyMsg>No queries found</EmptyMsg>
            </Empty>
          )}
        </QueryWrapper>
        {queryData && totalPages > 1 && (
          <Page>
            <Pagination
              pageChange={pageChange}
              totalPages={totalPages}
              page={page}
            />
          </Page>
        )}
      </Div>
    </>
  );
};

export default AllQueries;
