// import {Notifications} from 'react-push-notification';
import { Suspense, lazy, useState, useEffect } from "react";
import GlobalStyles from "./GlobalStyles";
import "boxicons";
import { DarkTheme, LightTheme } from "./Theme";
import { ThemeProvider } from "styled-components";
import Navbar from "./Components/CommonComponents/Navbar";
import Sidenav from "./Components/CommonComponents/Sidenav";
import Footer from "./Components/CommonComponents/Footer";
import { useLocation, Route, Routes } from "react-router-dom";
import * as ROUTES from "./Constants/routes";
import Loader from "./Components/CommonComponents/Loader";
import { ProtectedRoute, NotAccessibleRoute } from "./Helpers/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { login, loaderTrue, loaderFalse } from "./Redux/authorization";
import { refreshRoute } from "./Services/authorization";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TmlLeaderBoard from "./Pages/TmlLeaderBoard";
import AlumniProfile from "./Pages/AlumniProfilePage";
import EachQuery from "./Pages/AlumniSingleQuery";
import AllQueries from "./Pages/AllQueries";
import MyQuery from "./Pages/MyQuery";
import AlumniDashboard from "./Pages/AlumniDashboard";
const Announcements = lazy(() => import("./Pages/Announcements"));
const Blogs = lazy(() => import("./Pages/Blogs"));
const CreateAnnouncement = lazy(() => import("./Pages/CreateAnnouncement"));
const CreateBlog = lazy(() => import("./Pages/CreateBlog"));
const CreateQuery = lazy(() => import("./Pages/CreateQuery"));
const CreateEvent = lazy(() => import("./Pages/CreateEvent"));
const CreateTmlEvent = lazy(() => import("./Pages/CreateTmlEvent"));
const DevelopersPage = lazy(() => import("./Pages/DevelopersPage"));
const EachBlog = lazy(() => import("./Pages/EachBlog"));
const EachEvent = lazy(() => import("./Pages/EachEvent"));
const EachTeam = lazy(() => import("./Pages/EachTeam"));
const EditProfile = lazy(() => import("./Pages/EditProfile"));
const EditAlumni = lazy(() => import("./Pages/EditAlumni"));
const EditTeamProfile = lazy(() => import("./Pages/EditTeamProfile"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage"));
const Events = lazy(() => import("./Pages/Events"));
const EventReports = lazy(() => import("./Pages/EventReports"));
const ExplorePage = lazy(() => import("./Pages/ExplorePage"));
const LandingPage = lazy(() => import("./Pages/LandingPage"));
const MyAnnouncement = lazy(() => import("./Pages/MyAnnouncement"));
const MyBlog = lazy(() => import("./Pages/MyBlog"));
const MyEvents = lazy(() => import("./Pages/MyEvents"));
const Notes = lazy(() => import("./Pages/Notes"));
const Profile = lazy(() => import("./Pages/Profile"));
const RailwayAdmin = lazy(() => import("./Pages/RailwayAdmin"));
const RailwayUser = lazy(() => import("./Pages/RailwayUser"));
const TmlRegDeskDashboard = lazy(() => import("./Pages/TmlRegDeskDashboard"));
const TmlRegDeskTable = lazy(() => import("./Pages/TmlRegDeskTable"));
const Registration = lazy(() => import("./Pages/Registration"));
const TeamDashboard = lazy(() => import("./Pages/TeamDashboard"));
const Teams = lazy(() => import("./Pages/Teams"));
const TmlLandingPage = lazy(() => import("./Pages/TmlLandingPage"));
const UpdateAnnouncement = lazy(() => import("./Pages/UpdateAnnouncement"));
const UpdateBlog = lazy(() => import("./Pages/UpdateBlog"));
const UpdateEvent = lazy(() => import("./Pages/UpdateEvent"));
const UploadNotes = lazy(() => import("./Pages/UploadNotes"));
const UserRegistration = lazy(() => import("./Pages/UserRegistration"));
const UpdateQuery = lazy(() => import("./Pages/UpdateQuery"));
const TmlSponsors = lazy(() => import("./Pages/TmlSponsors"));
const TmlEvents = lazy(() => import("./Pages/TmlEvents"));
const TmlEventHeadDash = lazy(() => import("./Pages/TmlEventHeadDash"));
const TmlEachEvent = lazy(() => import("./Pages/TmlEachEvent"));
const LEADERBOARD = lazy(() => import("./Pages/TmlLeaderBoard"));
const MyLEADERBOARD = lazy(() => import("./Pages/TmlMyLeaderBoard"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const AlumnniRegistration = lazy(() => import("./Pages/AlumniRegistration"));
const CoreTeam = lazy(() => import("./Pages/CoreTeam"));
const AlumniAdminDashboard = lazy(() => import("./Pages/AlumniAdminDashboard"));
const FormsDashboard=lazy(()=>import("./Pages/FormsDashboard"))
const FormsThankyou=lazy(()=>import("./Pages/FormThankyou"))
const UserForm=lazy(()=>import("./Pages/UserForm"))
const UpdateForms=lazy(()=> import("./Pages/UpdateForms"))

const App = () => {
  const { loader, isLoggedIn } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDarkTheme, setDarkTheme] = useState(true);
  const ToggleTheme = () => setDarkTheme(!isDarkTheme);
  const [isNavOpen, setNavOpen] = useState(false);
  const GlobalTheme = isDarkTheme ? DarkTheme : LightTheme;
  const BlurStyles = isNavOpen ? { filter: "blur(3px)" } : null;
  const ToggleNavbar = () => setNavOpen(!isNavOpen);
  const [msalInstance, onMsalInstanceChange] = useState();

  useEffect(() => {
    ReactGA.initialize("UA-218482737-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    dispatch(loaderTrue());
    refreshRoute()
      .then((user) => {
        if (user.data.success === true) {
          dispatch(login(user.data));
        }
        dispatch(loaderFalse());
      })
      .catch((err) => {
        dispatch(loaderFalse());
      });
  }, []);

  //Setting NavOpen false if screen resized to 992px and above to remove the blur effect
  window.onresize = (e) => {
    if (e.target.innerWidth > 992 && isNavOpen) setNavOpen(false);
  };

  //Closing Navbar on clicking outside
  const handleClickOutside = () => {
    isNavOpen && setNavOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={GlobalTheme}>
        <GlobalStyles home={location.pathname === ROUTES.LANDINGPAGE || location.pathname === ROUTES.TML_LANDING_PAGE} />
        {loader ? (
          <Loader />
        ) : (
          <>
            {location.pathname !== ROUTES.LANDINGPAGE &&
              location.pathname !== ROUTES.USERREGISTRAION && location.pathname !== ROUTES.ALUMNIREGISTRATION && (
                <>
                  {/* <Notifications /> */}
                  <Navbar
                    isDarkTheme={isDarkTheme}
                    changeTheme={ToggleTheme}
                    toggleNav={ToggleNavbar}
                    msalInstance={msalInstance}
                  />
                  <Sidenav isNavOpen={isNavOpen} setNavOpen={setNavOpen} />
                </>
              )}
            <div style={BlurStyles} onClick={handleClickOutside}>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route
                    path={ROUTES.LANDINGPAGE}
                    element={
                      <NotAccessibleRoute>
                        <LandingPage
                          onMsalInstanceChange={onMsalInstanceChange}
                        />
                      </NotAccessibleRoute>
                    }
                  />
                  <Route
                    path={ROUTES.USERREGISTRAION}
                    element={
                      <NotAccessibleRoute>
                        <UserRegistration />
                      </NotAccessibleRoute>
                    }
                  />
                  {/* <Route
                    path={ROUTES.ACADEMICCALENDAR}
                    element={<AcademicCalendar />}
                  /> */}
                  <Route
                    path={ROUTES.ALUMNIREGISTRATION}
                    element={
                      <NotAccessibleRoute>
                        <AlumnniRegistration />
                      </NotAccessibleRoute>
                    }
                  />
                  <Route path={ROUTES.EVENTS} element={<Events />} />
                  <Route path={ROUTES.EACHEVENT} element={<EachEvent />} />
                  <Route
                    path={ROUTES.PROFILE}
                    element={
                      <ProtectedRoute authRole={["Student", "Team", "Alumni", "AlumniAdmin"]}>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.ALUMNIPROFILE}
                    element={
                      <ProtectedRoute authRole={["Student", "Team", "Alumni", "AlumniAdmin"]}>
                        <AlumniProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.EDITPROFILE}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <EditProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.EDITALUMNIPROFILE}
                    element={
                      <ProtectedRoute authRole={["Alumni"]}>
                        <EditAlumni />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.ALLQUERIES}
                    element={
                      <ProtectedRoute authRole={["Student", "Alumni"]}>
                        <AllQueries />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.MYQUERY}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <MyQuery />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.ALUMNIDASHBOARD}
                    element={
                      <ProtectedRoute authRole={["Alumni"]}>
                        <AlumniDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.ALUMNIADMINDASHBOARD}
                    element={
                      <ProtectedRoute authRole={["AlumniAdmin"]}>
                        <AlumniAdminDashboard/>
                      </ProtectedRoute>
                    }
                  />
                  <Route path={ROUTES.BLOGS_ALL} element={<Blogs />} />
                  <Route path={ROUTES.EACHBLOG} element={<EachBlog />} />
                  <Route
                    path={ROUTES.ANNOUNCEMENTS}
                    element={
                      <ProtectedRoute authRole={["Team", "Council", "Student", "Alumni"]}>
                        <Announcements />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={ROUTES.TEAMS} element={<Teams />} />
                  <Route path={ROUTES.EACHTEAM} element={<EachTeam />} />
                  <Route path={ROUTES.CORETEAM} element={<CoreTeam />} />
                  <Route
                    path={ROUTES.NOTES}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <Notes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.CREATEBLOG}
                    element={
                      <ProtectedRoute authRole={["Team", "Student", "Alumni"]}>
                        <CreateBlog />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.CREATEQUERY}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <CreateQuery />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.EACHQUERY}
                    element={
                      <ProtectedRoute authRole={["Team", "Student", "Alumni"]}>
                        <EachQuery />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.CREATEEVENT}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <CreateEvent />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.CREATETMLEVENT}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <CreateTmlEvent />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.CREATEANNOUNCEMENT}
                    element={
                      <ProtectedRoute authRole={["Team", "Council", "Alumni"]}>
                        <CreateAnnouncement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.ANNOUNCEINTERNSHIP}
                    element={
                      <ProtectedRoute authRole={["Alumni"]}>
                        <CreateAnnouncement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.UPLOADNOTES}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <UploadNotes />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.EDITTEAMPROFILE}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <EditTeamProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.MYANNOUNCEMENT}
                    element={
                      <ProtectedRoute authRole={["Team", "Council", "Alumni"]}>
                        <MyAnnouncement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.MYBLOG}
                    element={
                      <ProtectedRoute authRole={["Team", "Student", "Alumni"]}>
                        <MyBlog />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.MYEVENT}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <MyEvents />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.DEVELOPERSPAGE}
                    element={<DevelopersPage />}
                  />
                  <Route
                    path={ROUTES.EXPLOREPAGE}
                    element={
                      <ProtectedRoute authRole={["Student", "Team", "Alumni"]}>
                        <ExplorePage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={ROUTES.TEAMDASHBOARD}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <TeamDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.EVENTREPORTS}
                    element={
                      <ProtectedRoute authRole={["Council", "Team"]}>
                        <EventReports />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<ErrorPage />} />
                  <Route
                    path={ROUTES.REGISTRATION}
                    element={<Registration />}
                  />
                   <Route
                    path={ROUTES.FORMS}
                    element={<FormsDashboard />}
                  />
                  <Route
                    path={ROUTES.UPDATEANNOUNCEMENT}
                    element={
                      <ProtectedRoute authRole={["Team", "Council", "Alumni"]}>
                        <UpdateAnnouncement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.FORMTHANKYOU}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <FormsThankyou/>
                        </ProtectedRoute >
                      }
                  />
                  <Route
                    path={ROUTES.USERFORM}
                    element={<UserForm/>}
                  />
                   <Route
                    path={ROUTES.UPDATEFORM}
                    element={<UpdateForms/>}
                  />
                  <Route
                    path={ROUTES.UPDATEINTERNSHIP}
                    element={
                      <ProtectedRoute authRole={["Alumni"]}>
                        <UpdateAnnouncement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.UPDATEBLOG}
                    element={
                      <ProtectedRoute authRole={["Team", "Student", "Alumni"]}>
                        <UpdateBlog />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.UPDATEQUERY}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <UpdateQuery />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path={ROUTES.UPDATEEVENTS}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <UpdateEvent />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path={ROUTES.UPDATEEVENTS}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <UpdateEvent />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.RAILWAYUSER}
                    element={
                      <ProtectedRoute authRole={["Student"]}>
                        <RailwayUser />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.RAILWAYADMIN}
                    element={
                      <ProtectedRoute authRole={["Railway"]}>
                        <RailwayAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.TML_REGDESKDASHBOARD}
                    element={
                      <ProtectedRoute authRole={["RegDesk"]}>
                        <TmlRegDeskDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.TML_REGDESKTABLE}
                    element={
                      <ProtectedRoute authRole={["RegDesk"]}>
                        <TmlRegDeskTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.TML_LANDING_PAGE}
                    element={<TmlLandingPage />}
                  />
                  <Route path={ROUTES.TML_SPONSORS} element={<TmlSponsors />} />
                  <Route path={ROUTES.TML_EVENTS} element={<TmlEvents />} />
                  <Route
                    path={ROUTES.TML_EVENTHEADDASH}
                    element={
                      <ProtectedRoute authRole={["Student", "Team"]}>
                        <TmlEventHeadDash />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={ROUTES.MYLEADERBOARD}
                    element={
                      <ProtectedRoute authRole={["Team"]}>
                        <MyLEADERBOARD />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ROUTES.LEADERBOARD}
                    element={<TmlLeaderBoard />}
                  />
                  <Route
                    path={ROUTES.TML_EACHEVENT}
                    element={<TmlEachEvent />}
                  />

                  <Route path={ROUTES.PRIVACY} element={<Privacy />} />
                 
                </Routes>
                {location.pathname !== ROUTES.USERREGISTRAION && location.pathname !== ROUTES.ALUMNIREGISTRATION && <Footer />}
              </Suspense>
            </div>
          </>
        )}
      </ThemeProvider>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default App;
